.section_details{
  background-color: #fafafa;
  h2{
    + p{
      text-align: left;
      max-width: 100%;
      margin: 0 0 20px 0;
    }
  }
  p{
    font-weight: 400;
    font-size: 1.6rem;
    text-align: left;
    margin: 0 0 20px 0;
    display: none;
    &:last-child{
      margin: 0;
    }
    &:first-of-type{
      display: block!important;
      + p{
        display: block!important;
      }
    }
  }
  .r_m{
    text-align: left;
  }
  .read_more{
    @include respond-to(ipad){
      float: left;
    }
  }
}