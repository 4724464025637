.footer{
  background-color: #161616;
  padding: 15px 0 10px 0;
  @include respond-to(ipad){
    padding: 40px 0 10px 0;
  }
  .col{
    padding: 15px;
    min-height: 1px;
    text-align: center;
    @include respond-to(ipad){
      text-align: left;
      width: 33.333%;
      float: left;
      padding: 0 15px;
    }
    &:nth-child(2){
      text-align: center;
    }
    &:nth-child(3){
      text-align: center;
      @include respond-to(ipad) {
        text-align: right;
      }
    }
  }
  .logo_footer{
    display: block;
    img{
      max-width: 100%;
    }
  }
  .m_list{
    li{
      display: inline-block;
      padding: 0 15px;
      @include respond-to(desktop){
        padding: 0 30px;
      }
      a{
        display: block;
        text-transform: uppercase;
        font-size: 1.6rem;
        font-weight: 600;
        color: #fff;
        &:hover{
          color: #0247f8;
        }
      }
    }
  }
  .contacts{
    display: inline-block;
    text-align: left;
    p{
      padding: 5px 0;
    }
    span{
      margin: 0 10px 0 0;
      text-transform: uppercase;
    }
    span, a{
      display: inline-block;
      font-weight: 600;
      font-size: 1.6rem;
      color: #fff;
    }
    a{
      &:hover{
        color: #0247f8;
      }
    }
  }
  .copy{
    color: #fff;
    font-size: 1.4rem;
    text-align: center;
    font-weight: 400;
  }
}