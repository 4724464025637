.video_wrap{
  position: relative;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  max-width: 100%;
  .logo{
    display: block;
    margin: 0 auto;
  }
  #video{
    position: absolute;
    top: 0;
    left: 0;
    font-family: 'object-fit: cover;';
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    height: 100%;
    z-index: 2;
  }
  &_content{
    position: absolute;
    max-height: 100%;
    max-width: 1400px;
    width: 100%;
    left: 50%;
    top: 50%;
    right: auto;
    bottom: auto;
    z-index: 3;
    transform: translate(-50%, -50%);
    text-align: center;
    .choose_links{
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      padding: 10px 0 0;
      @include respond-to(ipad){
        flex-direction: row;
      }
      @include respond-to(desktop){
        padding: 20px 0 0;
      }
      @include respond-to(large_desktop){
        padding: 40px 0 0;
      }
      .btn{
        margin: 10px 0;
        min-width: 80%;
        @include respond-to(ipad){
          margin: 0 10px;
          min-width: 280px;
        }
        @include respond-to(desktop){
          margin: 0 15px;
          min-width: 300px;
        }
        @include respond-to(large_desktop){
          margin: 0 20px;
          min-width: 320px;
        }
      }
    }
  }
}