.section_questions_answers{
  text-align: left;
  background-attachment: fixed;
  @include respond-to(ipad){
    background-attachment: inherit;
  }
  &.overlay_c{
    &:before{
      background-color: rgba(230, 239, 246, .9);
    }
  }
  .col_left{
    min-height: 1px;
    padding: 0 15px;
    @include respond-to(ipad){
      float: left;
      width: 50%;
    }
    .tabs{
      padding-top: 30px;
      @include respond-to(ipad){
        padding-top: 50px;
      }
      @include respond-to(desktop){
        padding-top: 70px;
      }
      &_item{
        display: block;
        cursor: pointer;
        min-height: 1px;
        font-weight: 700;
        font-size: 2rem;
        position: relative;
        margin: 0 0 30px 0;
        padding-left: 30px;
        color: #c7c5c5;
        &:before{
          content: '';
          position: absolute;
          left: 0;
          top: -7px;
          bottom: -7px;
          width: 4px;
          background-color: #c7c5c5;
        }
        &.active{
          color: #000;
          &:before{
            background-color: #f74667;
          }
        }
      }
    }
  }
  .col_right{
    min-height: 1px;
    padding: 0 15px;
    @include respond-to(ipad){
      float: left;
      width: 50%;
    }
    .content_tab{
      display: none;
      &.opened{
        display: block;
      }
      ul{
        li{
          border-bottom: 1px solid #d5d5d5;
          padding: 10px 15px;
          .title{
            position: relative;
            display: block;
            cursor: pointer;
            font-weight: 600;
            font-size: 1.8rem;
            color: #000;
            padding-right: 30px;
            &:after{
              content: '+';
              position: absolute;
              top: 5px;
              right: 0;
              font-size: 2.4rem;
              line-height: .5;
              color: #f74667;
              -webkit-transition: all 200ms ease-in-out;
              -moz-transition: all 200ms ease-in-out;
              -ms-transition: all 200ms ease-in-out;
              -o-transition: all 200ms ease-in-out;
              transition: all 200ms ease-in-out;
              @include respond-to(ipad){
                font-size: 3rem;
              }
            }
            &.active{
              color: #f74667;
              &:after{
                -webkit-transform: rotate(45deg);
                -moz-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                -o-transform: rotate(45deg);
                transform: rotate(45deg);
              }
            }
          }
          .content{
            display: none;
            font-weight: 400;
            padding: 10px 0;
          }
        }
      }
    }
  }
  h3{
    font-weight: 700;
    color: #000;
    font-size: 2.4rem;
    margin: 0 0 10px 0;
    @include respond-to(ipad){
      font-size: 2.8rem;
    }
    @include respond-to(desktop){
      font-size: 3.2rem;
    }
  }
  p{
    font-size: 1.8rem;
    font-weight: 400;
    color: #000;
  }
}