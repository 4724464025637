.section_lecturer{
  background-color: #fafafa;
  .row{
    @include respond-to(ipad){
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: stretch;
      -ms-flex-align: stretch;
      align-items: stretch;
    }
  }
  .col_left{
    min-height: 1px;
    position: relative;
    @include respond-to(ipad){
      float: left;
      padding: 0 15px;
      width: 56%;
      text-align: left;
    }
    .in{
      background-color: #f1f1f1;
      padding: 15px;
      height: 100%;
      @include respond-to(ipad){
        padding: 35px 30px 35px 30px;
      }
      @include respond-to(desktop){
        padding: 45px 120px 45px 80px;
      }
      @include respond-to(large_desktop){
        padding: 55px 150px 55px 100px;
      }
      &_helpers{
        position: relative;
        width: 100%;
        height: 100%;
        &:before, &:after{
          content: '';
          position: absolute;
          width: 3px;
          background-color: #0247f8;
          height: 25px;
          top: 47px;
          left: -20px;
          @include respond-to(ipad){
            top: 47px;
            left: -20px;
            height: 30px;
          }
          @include respond-to(desktop){
            top: 47px;
            left: -20px;
            height: 40px;
          }
          @include respond-to(large_desktop){
            top: 47px;
            left: -20px;
            height: 50px;
          }
        }
        &:after{
          height: 80px;
          left: auto;
          right: -20px;
          top: auto;
          bottom: -40px;
          @include respond-to(ipad){
            height: 110px;
            bottom: -80px;
          }
          @include respond-to(desktop){
            height: 130px;
            bottom: -100px;
          }
          @include respond-to(large_desktop){
            height: 160px;
            bottom: -120px;
          }
        }
      }
      .title{
        font-weight: 700;
        margin: 0 0 10px;
        &:before{
          content: '';
          display: inline-block;
          margin-right: 10px;
          background-color: #000;
          height: 1px;
          width: 20px;
          vertical-align: middle;
          @include respond-to(ipad){
            margin-right: 15px;
            width: 25px;
          }
        }
      }
      .name{
        font-weight: 800;
        text-transform: uppercase;
        font-size: 2.6rem;
        color: #0247f8;
        margin: 0 0 20px 0;
        @include respond-to(ipad){
          font-size: 3.2rem;
        }
        @include respond-to(desktop){
          font-size: 3.6rem;
        }
        @include respond-to(large_desktop){
          font-size: 4rem;
        }
      }
      .description{
        color: #515151;
        p{
          color: #515151;
          font-weight: 400;
          font-size: 1.4rem;
          margin: 0 0 20px;
        }
      }
    }
    .socials_list{
      text-align: center;
      @extend .clearfix;
      position: static;
      padding: 15px 0;
      @include respond-to(ipad){
        text-align: left;
        padding: 0;
        position: absolute;
        bottom: -40px;
        right: 100px;
      }
      @include respond-to(desktop){
        bottom: -40px;
        right: 120px;
      }
      @include respond-to(large_desktop){
        bottom: -40px;
        right: 140px;
      }
      li{
        padding: 0 15px;
        display: inline-block;
        @include respond-to(ipad){
          float: left;
        }
        a{
          text-transform: uppercase;
          font-weight: 700;
          font-size: 1.4rem;
          color: #000;
          &:hover{
            color: #0247f8;
          }
        }
      }
    }
  }
  .col_right{
    min-height: 1px;
    @include respond-to(ipad){
      float: left;
      padding: 0 15px;
      width: 44%;
      text-align: right;
    }
    .in{
      position: relative;
      display: inline-block;
      background-color: #ebf4f1;
      max-width: 430px;
      margin: 0 0 0 auto;
      height: 100%;
      .img{
        text-align: right;
        font-size: 0;
        img{
          display: inline-block;
          max-width: 100%;
        }
      }
      .title{
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        font-size: 1.8rem;
        font-weight: 600;
        max-width: 100%;
        background-color: #fff;
        padding: 10px 15px;
        text-align: center;
        &:before{
          content: '';
          display: inline-block;
          margin-right: 10px;
          background-color: #000;
          height: 1px;
          width: 20px;
          vertical-align: middle;
          @include respond-to(ipad){
            margin-right: 15px;
            width: 25px;
          }
        }
      }
    }
  }
}