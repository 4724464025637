.section_logos{
  h3{
    text-transform: uppercase;
    font-weight: 700;
    font-family: Myriad Pro, sans-serif;
    letter-spacing: -.5px;
    color: #000;
    font-size: 2rem;
    padding-bottom: 30px;
    @include respond-to(ipad){
      font-size: 2.2rem;
      padding-bottom: 60px;
    }
    @include respond-to(desktop){
      padding-bottom: 70px;
      font-size: 2.4rem;
    }
    @include respond-to(large_desktop){
      padding-bottom: 80px;
    }
  }
  .logos_wrap{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 -15px;
    @include respond-to(ipad){
      //justify-content: space-between;
    }
    &_item{
      padding: 15px;
      @include respond-to(ipad){
        padding: 15px 25px;
      }
      @include respond-to(desktop){
        padding: 15px 30px;
      }
      @include respond-to(large_desktop){
        padding: 15px 45px;
      }
    }
  }
}