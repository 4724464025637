.section_advantages{
  .advantages_list{
    padding-top: 30px;
    @extend .clearfix;
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    flex-direction: row;
    -webkit-box-pack: center;    -ms-flex-pack: center;    justify-content: center;
    li{
      display: flex;
      flex-direction: column;
      float: left;
      width: 50%;
      min-height: 1px;
      padding: 15px 15px 80px 15px;
      @include respond-to(ipad){
        padding-bottom: 90px;
        width: 33.333%;
      }
      @include respond-to(desktop){
        padding-bottom: 100px;
        width: 16.6667%;
      }
      div{
        flex-direction: column;
        position: relative;
        align-items: center;
        padding: 30px;
        height: 100%;
        display: flex;
        background-color: #fff;
        -webkit-box-shadow: 0 0 50px 10px rgba(211,209,209,1);
        -moz-box-shadow: 0 0 50px 10px rgba(211,209,209,1);
        box-shadow: 0 0 50px 10px rgba(211,209,209,1);
        img{
          max-width: 100%;
          margin: auto;
        }
      }
      h5{
        position: absolute;
        top: 100%;
        font-size: 14px;
        font-weight: 600;
        color: #000;
        line-height: 1.5;
        left: 0;
        right: 0;
        padding: 15px 5px 0;
        @include respond-to(ipad){
          font-size: 16px;
        }
      }
    }
  }
}