.section_source{
  padding: 60px 0;
  text-align: center;
  @include respond-to(ipad){
    text-align: left;
    padding: 100px 0;
  }
  @include respond-to(desktop){
    padding: 140px 0;
  }
  @include respond-to(large_desktop){
    padding: 170px 0 200px;
  }
  .col_inner{
    min-height: 1px;
    padding: 15px;
    @include respond-to(ipad){
      float: left;
      padding: 0 15px;
    }
    &:first-child{
      @include respond-to(ipad){
        width: 43%;
      }
    }
    &:last-child{
      @include respond-to(ipad){
        width: 57%;
      }
    }
    &_left{
      background-color: transparent;
      @include respond-to(ipad){}
      @include respond-to(desktop){}
      @include respond-to(large_desktop){}
    }
    &_right{
      position: relative;
      background-color: #f5f5f5;
      padding: 15px;
      @include respond-to(ipad){
        padding: 50px 70px;
      }
      @include respond-to(desktop){
        padding: 80px 100px;
      }
      @include respond-to(large_desktop){
        padding: 100px 140px;
      }
      &:before{
        content: '';
        position: absolute;
        width: 3px;
        height: 50px;
        background-color: #0247f8;
        display: none;
        @include respond-to(ipad){
          position: absolute;
          display: block;
          left: 50px;
          top: 56px;
          width: 3px;
          height: 50px;
        }
        @include respond-to(desktop){
          left: 64px;
          top: 95px;
        }
        @include respond-to(large_desktop){
          left: 110px;
          top: 107px;
        }
      }
      .helper_lines{
        position: relative;
        &:before, &:after{
          content: '';
          display: none;
          background-color: #0247f8;
          @include respond-to(ipad){
            position: absolute;
            display: block;
            width: 3px;
            height: 90px;
            right: -30px;
            top: -78px;
          }
          @include respond-to(desktop){
            right: -30px;
            height: 120px;
            top: -110px;
          }
          @include respond-to(large_desktop){
            right: -30px;
            top: -154px;
          }
        }
        &:after{
          left: auto;
          top: auto;
          @include respond-to(ipad){
            right: 56px;
            bottom: -68px;
          }
          @include respond-to(desktop){
            right: 100px;
            bottom: -120px;
          }
          @include respond-to(large_desktop){
            right: 110px;
            bottom: -168px;
          }
        }
      }
      p{
        margin-bottom: 20px;
        font-size: 1.4rem;
        @include respond-to(ipad){
          font-size: 1.2rem;
        }
        @include respond-to(desktop){
          font-size: 1.6rem;
        }
      }
      .btn{
        &:hover{
          color: #ef4162;
        }
      }
    }
    h4{
      color: #0247f8;
      font-weight: 800;
      text-transform: uppercase;
      line-height: 1.3;
      font-size: 2.8rem;
      @include respond-to(ipad){
        font-size: 3.2rem;
      }
      @include respond-to(desktop){
        font-size: 3.6rem;
      }
      @include respond-to(large_desktop){
        font-size: 4rem;
      }
    }
    p{
      font-weight: 400;
      margin: 0 0 10px;
      b, strong{
        font-weight: 600;
      }
    }
    .start_date{
      position: relative;
      color: #f74667;
      font-size: 1.6rem;
      font-weight: 600;
      padding-left: 40px;
      margin: 0 0 25px;
      display: inline-block;
      @include respond-to(ipad){
        display: block;
      }
      &:before{
        content: '';
        width: 30px;
        height: 1px;
        background-color: #000;
        position: absolute;
        left: 0;
        top: 50%;
        -webkit-transform: translate(0, -50%);
        -moz-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        -o-transform: translate(0, -50%);
        transform: translate(0, -50%);
      }
    }
    ul{
      margin: 0 0 25px;
      &.labels{
        font-size: 0;
        li{
          font-size: 1rem;
          display: inline-block;
          padding: 4px 6px;
          background-color: #d4dbfd;
          margin: 0 8px 0 0;
          font-weight: 700;
          &:last-child{
            margin: 0;
          }
        }
      }
      &.desc_list{
        li{
          display: inline-block;
          width: 60px;
          height: 60px;
          margin: 0 10px 0 0;
          &:last-child{
            margin: 0;
          }
          @include respond-to(ipad){
            width: 75px;
            height: 75px;
          }
          @include respond-to(desktop){
            width: 90px;
            height: 90px;
          }
          @include respond-to(large_desktop){
            width: 105px;
            height: 105px;
          }
          .desc_list_wr{
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            height: 100%;
            -webkit-box-shadow: 0 0 50px 10px rgba(211,209,209,1);
            -moz-box-shadow: 0 0 50px 10px rgba(211,209,209,1);
            box-shadow: 0 0 50px 10px rgba(211,209,209,1);
            .n{
              font-weight: 600;
              font-size: 2rem;
              @include respond-to(ipad){
                font-size: 2.4rem;
              }
              @include respond-to(desktop){
                font-size: 2.8rem;
              }
              @include respond-to(large_desktop){
                font-size: 3rem;
              }
            }
            .w{
              font-weight: 400;
              font-size: 1.4rem;
              @include respond-to(ipad){
                font-size: 1.6rem;
              }
              @include respond-to(desktop){
                font-size: 1.8rem;
              }
              @include respond-to(large_desktop){
                font-size: 2rem;
              }
            }
          }
        }
      }
    }
    .price{
      font-weight: 700;
      margin: 0 0 35px;
      font-size: 2.6rem;
      @include respond-to(ipad){
        font-size: 2.8rem;
      }
      @include respond-to(desktop){
        font-size: 3rem;
      }
      @include respond-to(large_desktop){
        font-size: 3.4rem;
      }
    }
  }
}