@CHARSET "ISO-8859-1";
/* Reset CSS
 * --------------------------------------- */
body,div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,pre,
form,fieldset,input,textarea,p,blockquote,th,td {
  padding: 0;
  margin: 0;
}
html{
  height: 100%;
}
*{
  box-sizing: border-box;
}
a{
  text-decoration:none;
}
table {
  border-spacing: 0;
}
fieldset,img {
  border: 0;
}
address,caption,cite,code,dfn,em,strong,th,var {
  font-weight: normal;
  font-style: normal;
}
strong{
  font-weight: bold;
}
ol,ul {
  list-style: none;
  margin:0;
  padding:0;
  li{
    min-height: 1px;
    height: auto;
  }
}
caption,th {
  text-align: left;

}
h1,h2,h3,h4,h5,h6 {
  color: #000;
  font-size: 100%;
  margin:0;
  padding:0;
}
q:before,q:after {
  content:'';
}
abbr,acronym {
  border: 0;
}
*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  &:focus{
    outline: none;
  }
}
/* Custom CSS
 * --------------------------------------- */
body{
  color: #000;
  line-height: 1.5;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 1.4rem;
  height: 100%;
}
.scroll_y{
  overflow-y: scroll;
}
.wrapper{
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  overflow: hidden;
  min-height: 100%;

  //display: table;
  //height: 100%;
}
.container{
  position: relative;
  margin: 0 auto;
  max-width: 1400px;
  padding: 0 15px;
  width: 100%;
  &:before{
    content: '';
    display: table;
  }
  &:after{
    content: '';
    display: table;
    clear: both;
  }
}
.row{
  margin: 0 -15px;
  &:before{
    content: '';
    display: table;
  }
  &:after{
    content: '';
    display: table;
    clear: both;
  }
}
.main{}
img{
  max-width: 100%;
}
p{
  font-size: 1.4rem;
}
h1, h2, h3, h4, h5, h6{
  color: #000;
  font-weight: 500;
  line-height: 1.2;
}
h1{
  font-size: 4rem;
  color: #fff;
  font-weight: 800;
  @include respond-to(ipad) {
    font-size: 4.8rem;
  }
  @include respond-to(desktop) {
    font-size: 5.4rem;
  }
  @include respond-to(large_desktop) {
    font-size: 6.4rem;
  }
}
h2{
  position: relative;
  display: inline-block;
  font-weight: 700;
  color: #0e0e0e;
  font-size: 2.8rem;
  padding: 0 0 20px;
  margin: 0 0 20px;
  @include respond-to(ipad){
    font-size: 3.2rem;
  }
  @include respond-to(desktop){
    padding: 0 0 30px;
    font-size: 3.8rem;
  }
  @include respond-to(large_desktop){
    padding: 0 0 40px;
    font-size: 4.2rem;
  }
  &:before{
    content: '';
    position: absolute;
    height: 3px;
    bottom: 10px;
    width: 40%;
    left: 50%;
    background-color: #0247f8;
    transform: translate(-50%, 0);
    @include respond-to(ipad){}
    @include respond-to(desktop){
      bottom: 15px;
    }
    @include respond-to(large_desktop){
      bottom: 20px;
    }
  }
}
h3{

  @include respond-to(ipad){

  }
}
// helpers

.b_s{
  -webkit-box-shadow: 40px 40px 49px -41px rgba(211,209,209,1);
  -moz-box-shadow: 40px 40px 49px -41px rgba(211,209,209,1);
  box-shadow: 40px 40px 49px -41px rgba(211,209,209,1);
}

.text_a_left{
  text-align: center;
  @include respond-to(ipad){
    text-align: left;
  }
}
.text_a_right{
  text-align: center;
  @include respond-to(ipad){
    text-align: right;
  }
}
.text_a_center{
  text-align: center;
}
a{
  transition: all 200ms ease-in-out;
  color: #0247f8;
  font-weight: 600;
  &.btn{
    display: inline-block;
    color: #000;
    font-size: 1.6rem;
    background-color: #fff;
    padding: 10px 20px;
    border: 1px solid transparent;
    @include respond-to(ipad){
      padding: 12px 30px;
    }
    @include respond-to(desktop){
      border: 2px solid transparent;
      padding: 15px 35px;
    }
    @include respond-to(large_desktop){
      padding: 20px 40px;
    }
    &.red{
      background-color: #ef4162;
      color: #fff;
      &:hover{
        border-color: #ef4162;
      }
    }
    &.small{
      @include respond-to(ipad){
        padding: 8px 25px;
      }
      @include respond-to(desktop){
        padding: 10px 32px;
      }
      @include respond-to(large_desktop){
        padding: 10px 35px;
      }
    }
    &:hover{
      color: #fff;
      background-color: transparent;
      border-color: #fff;
    }
  }
  &.read_more{
    position: relative;
    padding-right: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    display: inline-block;
    color: #0247f8;
    font-size: 1.6rem;
    text-transform: uppercase;
    font-weight: 600;
    + .hidden_more{
      display: none;
    }
    &:before, &:after{
      content: '';
      position: absolute;
      right: 0;
      top: 50%;
      border: 5px solid transparent;
      border-top: 5px solid #0247f8;
    }
    &:after{
      border-top: 5px solid #fff;
      top: calc(50% - 2px);
    }
    &.active{
      &:before, &:after{
        border-bottom: 5px solid #0247f8;
        border-top: 0;
      }
      &:after{
        border-bottom: 5px solid #fff;
        border-top: 0;
        top: calc(50% + 2px);
      }
    }
  }
}

.clearfix{
  position: relative;
  &:before{
    content: '';
    display: table;
  }
  &:after{
    content: '';
    display: table;
    clear: both;
  }
}

#to_top{
  display: none;
  position: fixed;
  bottom: 15px;
  right: 15px;
  line-height: 1;
  z-index: 999;
  padding: 6px 13px;
  background: rgba(255,255,255,.75);
  margin: auto;
  align-items: center;
  i{
    font-size: 4rem;
    color:  rgba(0,145,126,.85);
    background: none;
    line-height: 1;
  }
}

.logo{
  position: relative;
  display: inline-block;
  z-index: 2;
  max-width: 120px;
  @include respond-to(ipad){
    max-width: 100%;
  }
  img{
    display: inline-block;
    width: auto;
    max-width: 100%;
    margin: 0 auto;
  }
}

/* Icon 1 */

.btn_menu {
  display: block;
  //position: absolute;
  position: fixed;
  z-index: 99999;
  width: 40px;
  height: 30px;
  top: 15px;
  right: 15px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
  @include respond-to(ipad){
    height: 45px;
    top: 30px;
    right: 30px;
  }
  @include respond-to(large_desktop){
    width: 50px;
    top: 40px;
    right: 40px;
  }
  span{
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: #fff;
    border-radius: 6px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    @include respond-to(ipad){
      height: 5px;
    }
    &:nth-child(1) {
      top: 0;
    }
    &:nth-child(2) {
      top: 12px;
      @include respond-to(large_desktop){
        top: 18px;
      }
    }
    &:nth-child(3) {
      top: 24px;
      @include respond-to(large_desktop){
        top: 36px;
      }
    }
  }
  &.open{
    span{
      background-color: #000;
      &:nth-child(1){
        top: 18px;
        -webkit-transform: rotate(135deg);
        -moz-transform: rotate(135deg);
        -o-transform: rotate(135deg);
        transform: rotate(135deg);
      }
      &:nth-child(2) {
        opacity: 0;
        left: -60px;
      }
      &:nth-child(3) {
        top: 18px;
        -webkit-transform: rotate(-135deg);
        -moz-transform: rotate(-135deg);
        -o-transform: rotate(-135deg);
        transform: rotate(-135deg);
      }
    }
  }
}

b, strong{
  font-weight: 600;
}

.common_s{
  position: relative;
  background-repeat: no-repeat!important;
  background-size: cover;
  background-position: 50% 50%;
  text-align: center;
  padding: 45px 0;
  z-index: 1;
  @include respond-to(ipad){
    padding: 70px 0;
  }
  @include respond-to(desktop){
    padding: 90px 0;
  }
  @include respond-to(large_desktop){
    padding: 110px 0;
  }
  h2{
    text-transform: uppercase;
    font-weight: 800;
  }
  &.overlay_c{
    ul{
      display: block;
      &.steps_list{
        display: flex;
      }
      li{
        min-height: 1px;
        height: auto;
      }
    }
    &:after{
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background: rgba(255, 255, 255, .75);
      z-index: 1;
    }
  }
  h2{
    &+p{
      max-width: 80%;
      margin: 0 auto 25px;
      line-height: 1.5;
    }
  }
  .container{
    z-index: 2;
  }
}
form{
  input[type=number]{
    -moz-appearance: textfield;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  input{
    background-color: #f0f0f1;
    border: 0;
    color: #747474;
    padding: 20px;
    &:focus{
      outline: none;
    }
    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: #747474;
      font-weight: 600;
      font-size: 1.4rem;
    }
    &::-moz-placeholder { /* Firefox 19+ */
      color: #747474;
      font-weight: 600;
      font-size: 1.4rem;
    }
    &:-ms-input-placeholder { /* IE 10+ */
      color: #747474;
      font-weight: 600;
      font-size: 1.4rem;
    }
    &:-moz-placeholder { /* Firefox 18- */
      color: #747474;
      font-weight: 600;
      font-size: 1.4rem;
    }
  }
}
// develop page
.services_list{
  @extend .row;
  counter-reset: item;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  li{
    counter-increment: item;
    color: #000;
    padding: 15px;
    width: 100%;
    text-align: center;
    min-height: 1px;
    @include respond-to(ipad){
      text-align: left;
      width: 50%;
      float: left;
    }
    @include respond-to(desktop){
      width: 33.333%;
    }
    &:before{
      content: '' counter(item, decimal-leading-zero)'/' ;
      counter-increment: li;
      top: -10px;
      display: block;
      margin: 0 0 10px;
      font-size: 1.6rem;
      font-weight: 600;
      text-transform: uppercase;
    }
    .inner_wrap{
      h4{
        font-size: 1.8rem;
        text-transform: uppercase;
        color: #000;
        font-weight: 700;
        margin: 0 0 10px;
      }
      p{
        font-weight: 400;
        font-size: 1.5rem;
        margin: 0 0 10px;
        color: #000;
      }
      .read_more{
        font-weight: 700;
        font-size: 1.3rem;
        text-transform: uppercase;
        margin: 0 0 10px;
        color: #000;
        &:before{
          top: 44%;
        }
        &:after {
          border-top: 5px solid #fff;
          top: calc(44% - 2px);
        }
        &.hidden{
          display: none;
        }
        &.active{
          &:before{
            border-bottom-color: #000;
          }
        }
        &:before{
          border-top-color: #000;
        }
      }
      .hidden_more{
        p{
          text-transform: uppercase;
          font-size: 1.3rem;
          b, strong{
            font-weight: 700;
          }
        }
        .get_cons{
          position: relative;
          font-weight: 700;
          display: inline-block;
          color: #f74667;
          font-size: 1.3rem;
          text-transform: uppercase;
          margin: 0 0 10px;
          i{
            padding-left: 10px;
            @include respond-to(ipad){
              padding-left: 15px;
            }
          }
        }
        .a_read_more{
          position: relative;
          display: inline-block;
          font-size: 1.3rem;
          padding-right: 20px;
          color: #000;
          text-transform: uppercase;
          font-weight: 700;
          &:before, &:after{
            content: '';
            position: absolute;
            right: 0;
            top: 44%;
            border: 5px solid transparent;
            border-top: 5px solid #000;
          }
          &:after{
            border-top: 5px solid #fff;
            top: calc(44% - 2px);
          }
          &:before, &:after{
            border-bottom: 5px solid #000;
            border-top: 0;
          }
          &:after{
            border-bottom: 5px solid #fff;
            border-top: 0;
            top: calc(44% + 2px);
          }
        }
      }
    }
  }
}
.steps_list{
  @extend .clearfix;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  counter-reset: item;
  li{
    padding: 0 15px;
    min-height: 1px;
    width: 50%;
    counter-increment: item;
    @include respond-to(ipad){
      float: left;
      width: 33.333%;
    }
    @include respond-to(desktop){
      width: 33.333%;
    }
    @include respond-to(large_desktop){
      width: 20%;
    }
    a{
      height: 100%;
      justify-content: space-between;
      padding: 25px 15px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      &:before{
        content: counter(item, decimal-leading-zero);
        counter-increment: li;
        display: block;
        margin: 0 0 10px 0;
        color: #000;
        font-size: 1.3rem;
        font-weight: 700;
      }
      img{
        max-width: 100%;
      }
      h5{
        padding: 15px 0 0 0;
        font-size: 1.2rem;
        text-transform: uppercase;
        color: #000;
        font-weight: 700;
      }
      &:hover{
        background-color: #fff;
        -webkit-box-shadow: 0px 0px 70px -8px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 0px 70px -8px rgba(0,0,0,0.75);
        box-shadow: 0px 0px 70px -8px rgba(0,0,0,0.75);
      }
    }
    .hidden_content{
      display: none;
    }
  }
}
.steps_answers{
  width: 100%;
  margin: 0 auto;
  max-width: 1170px;
  padding: 40px 0;
  @include respond-to(ipad){
    padding: 60px 0;
  }
  @include respond-to(desktop){
    padding: 80px 0;
  }
  p{
    color: #000;
    font-weight: 600;
    font-size: 16px;
    padding: 0 0 15px;
    &:last-of-type{
      padding-bottom: 40px;
    }
  }
  a{
    &.btn{
      width: 100%;
      max-width: 300px;
      margin: 0 auto;
      background-color: #f74667;
      border-color: #f74667;
      color: #fff;
      &:hover{
        background-color: transparent;
        border-color: #f74667;
        color: #f74667;
      }
    }
  }
}
.ov_h{
  overflow-y: hidden;
}