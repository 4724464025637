.section_info{
  position: relative;
  .container{
    z-index: 2;
  }
  .row{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    @include respond-to(ipad){
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
    }
  }
  .col{
    &_left{
      text-align: left;
      min-height: 1px;
      padding: 15px;
      @include respond-to(ipad){
        width: 55%;
        padding: 0 15px;
        float: left;
      }
      img{
        width: 100%;
        max-width: 100%;
        display: block;
      }
    }
    &_right{
      text-align: left;
      min-height: 1px;
      padding: 15px;
      @include respond-to(ipad){
        width: 45%;
        padding: 0 15px 0 45px;
        float: left;
      }
      h5{
        margin: 0 0 15px 0;
        font-weight: 700;
        height: auto;
        color: #000;
        min-height: 0;
        font-size: 1.6rem;
        @include respond-to(ipad){
          margin: 0 0 25px 0;
          font-size: 1.8rem;
        }
        @include respond-to(desktop){
          font-size: 2rem;
        }
      }
      ul{
        display: block;
        @include respond-to(ipad){
          margin: 0 0 0 -15px;
        }
        li{
          position: relative;
          font-weight: 400;
          margin: 0 0 10px 0;
          height: auto;
          min-height: 0;
          font-size: 1.4rem;
          padding-left: 15px;
          @include respond-to(desktop){
            font-size: 1.6rem;
          }
          &:last-child{
            margin: 0;
          }
          &:before{
            content: '';
            width: 6px;
            height: 6px;
            display: inline-block;
            vertical-align: middle;
            border-radius: 50%;
            background-color: #0247f8;
            position: absolute;
            left: 0;
            top: 50%;
            -webkit-transform: translate(0, -50%);
            -ms-transform: translate(0, -50%);
            transform: translate(0, -50%);
          }
        }
      }
    }
  }
}