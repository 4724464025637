.section_program{
  overflow: hidden;
  .program_list{
    position: relative;
    text-align: left;
    max-width: 820px;
    margin: 0 auto;
    &:before{
      content: '';
      position: absolute;
      top: 30px;
      left: 22%;
      bottom: 100px;
      width: 1px;
      background-color: #dadada;
      z-index: 1;
      @include respond-to(ipad){
        left: 12%;
      }
    }
    li{
      &.last{
        .img{
          &:after{
            content: '';
            position: absolute;
            width: 1px;
            height: 9999px;
            background-color: #fff;
            right: -1%;
            z-index: 21;
            top: 49px;
          }
        }
        .in{
          .cont{
            &_inner{
              border-bottom: 0;
            }
          }
        }
      }
      .in{
        @extend .clearfix;
        .img{
          position: relative;
          width: 22%;
          float: left;
          padding-right: 30px;
          @include respond-to(ipad){
            width: 12%;
          }
          &:before{
            content: '';
            position: absolute;
            right: -5px;
            top: 50%;
            width: 5px;
            border-radius: 50%;
            height: 5px;
            border: 2px solid #0247f8;
            background-color: #fff;
            -webkit-transform: translate(0, -50%);
            -moz-transform: translate(0, -50%);
            -ms-transform: translate(0, -50%);
            -o-transform: translate(0, -50%);
            transform: translate(0, -50%);
            -webkit-transition: all 200ms ease-in-out;
            -moz-transition: all 200ms ease-in-out;
            -ms-transition: all 200ms ease-in-out;
            -o-transition: all 200ms ease-in-out;
            transition: all 200ms ease-in-out;
            z-index: 2;
          }
          img{
            max-width: 60px;
            width: auto;
          }
          &.active{
            &:before{
              background-color: #0247f8;
              -webkit-box-shadow: 0px 0px 0px 8px rgba(158,205,253,1);
              -moz-box-shadow: 0px 0px 0px 8px rgba(158,205,253,1);
              box-shadow: 0px 0px 0px 8px rgba(158,205,253,1);
              @include respond-to(ipad){
                -webkit-box-shadow: 0px 0px 0px 15px rgba(158,205,253,1);
                -moz-box-shadow: 0px 0px 0px 15px rgba(158,205,253,1);
                box-shadow: 0px 0px 0px 15px rgba(158,205,253,1);
              }
            }
          }
        }
        .cont{
          width: 78%;
          float: left;
          padding-left: 30px;
          -webkit-transform: translate(0, -25px);
          -moz-transform: translate(0, -25px);
          -ms-transform: translate(0, -25px);
          -o-transform: translate(0, -25px);
          transform: translate(0, -25px);
          @include respond-to(ipad){
            width: 88%;
            -webkit-transform: translate(0, -25px);
            -moz-transform: translate(0, -25px);
            -ms-transform: translate(0, -25px);
            -o-transform: translate(0, -25px);
            transform: translate(0, -25px);
          }
          &_inner{
            padding: 20px 0;
            border-bottom: 1px dashed #dadada;
          }
          .module{
            color: #0247f8;
            font-size: 1.2rem;
            font-weight: 700;
            text-transform: uppercase;
          }
          h5{
            color: #000;
            text-transform: uppercase;
            font-size: 1.8rem;
            font-weight: 600;
            padding: 5px 0;
            @include respond-to(ipad){
              font-size: 2rem;
            }
          }
          .themes_list_wrap{
            span{
              position: relative;
              display: inline-block;
              padding: 5px 20px 5px 0;
              font-size: 1.6rem;
              font-weight: 600;
              cursor: pointer;
              &.down{
                &:before, &:after{
                  content: '';
                  position: absolute;
                  right: 0;
                  top: 50%;
                  border: 5px solid transparent;
                  border-top: 5px solid black;
                }
                &:after{
                  border-top: 5px solid white;
                  top: calc(50% - 2px);
                }
                &.top{
                  &:before, &:after{
                    border-bottom: 5px solid black;
                    border-top: 0;
                  }
                  &:after{
                    border-bottom: 5px solid white;
                    border-top: 0;
                    top: calc(50% + 2px);
                   }
                }
              }
            }
            .hidden_list{
              display: none;
              li{
                font-size: 1.2rem;
                font-weight: 400;
                &:before{
                  content: '- ';
                  display: inline-block;
                  padding-right: 3px;
                }
              }
            }
          }
        }
      }
    }
  }
}