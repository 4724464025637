.banner{
  position: relative;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat!important;
  padding: 80px 0;
  text-align: center;
  &.overlay_c{
    &:before{
      content: '';
      position: absolute;
      background-color: rgba(81, 80, 80, .5);
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
  .container{
    z-index: 1;
  }
  h3{
    font-size: 4.6rem;
    color: #fff;
    font-weight: 700;
    margin: 0 0 20px;
  }
}