.header{
  padding: 15px 0;
  position: relative;
  text-align: center;
  @include respond-to(ipad){
    padding: 25px 0;
  }
  @include respond-to(desktop){
    padding: 30px 0;
  }
  @include respond-to(large_desktop){
    padding: 40px 0;
  }
  > .container{
    position: static;
  }
  #particles-js{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    max-height: 100%;

    line-height: 0;
    width: 100%;
    background-color: #02003a;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat!important;
    canvas{
      height: 100%;
      width: 100%;
    }
  }
  #menu_wrap{
    position: fixed;
    top: 0;
    left: -100%;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    z-index: 11;
    background-color: #f3f2f2;
    transition: all .35s ease-in-out;
    &.show{
      left: 0;
    }
    .container{
      height: 100%;
    }
    .logo_menu{
      position: absolute;
      top: 40px;
      left: 15px;
      line-height: 0;
      display: inline-block;
    }
    .menu_wrap_inner{
      position: relative;
      padding-top: 150px;
      text-align: center;
      @include respond-to(ipad){
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        max-width: 100%;
        padding-top: 0;
        transform: translate(0, -50%);
        display: flex;
        align-items: center;
        flex-direction: row;
        text-align: left;
      }
      @include respond-to(desktop){}
      h3{
        text-transform: uppercase;
        color: #e3e2e2;
        font-weight: 700;
        font-size: 14rem;
        display: none;
        @include respond-to(ipad){
          display: block;
          font-size: 16rem;
          width: 75%;
        }
        @include respond-to(desktop){
          font-size: 22rem;
        }
        @include respond-to(large_desktop){
          font-size: 30rem;
        }
      }
      .menu{
        @include respond-to(ipad){
          padding-left: 20px;
          width: 25%;
        }
        li{
          padding: 5px 0;
          @include respond-to(ipad){
            padding: 0;
          }
          a{
            color: #161616;
            font-size: 2rem;
            font-weight: 600;
            @include respond-to(ipad){
              font-size: 2.2rem;
            }
            @include respond-to(desktop){
              font-size: 2.4rem;
            }
            @include respond-to(large_desktop){
              font-size: 3rem;
            }
          }
          &.active{
            a{
              position: relative;
              color: #0247f8;
              &:before{
                content: '';
                width: 20px;
                height: 2px;
                background-color: #0247f8;
                display: inline-block;
                position: absolute;
                left: -30px;
                top: 50%;
                line-height: 0;
                transform: translate(0, -50%);
              }
            }
          }
          &:hover{
            a{
              position: relative;
              color: #0247f8;
              &:before{
                content: '';
                width: 20px;
                height: 2px;
                background-color: #0247f8;
                display: inline-block;
                position: absolute;
                left: -30px;
                top: 50%;
                line-height: 0;
                transform: translate(0, -50%);
              }
            }
          }
        }
      }
    }
  }
}