@charset "UTF-8";
@font-face {
  font-family: fontawesome;
  /* Имя шрифта */
  src: url(../fonts/FontAwesome.otf);
  /* Путь к файлу со шрифтом */ }

/*
*/
/*div.logo {
  background: url("logo.png") no-repeat;
  @include image-2x("logo2x.png", 100px, 25px);
}*/
/*.foo {
  @include background-gradient(red, black, 'vertical');
}*/
/*.foo {
  background: -webkit-linear-gradient(top, red, black);
  background: linear-gradient(to bottom, red, black);
}*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video, main {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline; }

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

html {
  box-sizing: border-box;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
  min-height: 100%;
  font-size: 10px; }

body {
  font-weight: 300;
  font-size: 1.4rem;
  color: #000;
  line-height: 1.5;
  min-height: 100%; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

ol, ul, li {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: ''; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

a {
  text-decoration: none; }
  a:active {
    text-decoration: none; }
  a:visited {
    text-decoration: none; }
  a:focus {
    text-decoration: none; }
  a:hover {
    text-decoration: none; }

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }
  *:focus {
    outline: none; }

html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer; }

@CHARSET "ISO-8859-1";
/* Reset CSS
 * --------------------------------------- */
body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre,
form, fieldset, input, textarea, p, blockquote, th, td {
  padding: 0;
  margin: 0; }

html {
  height: 100%; }

* {
  box-sizing: border-box; }

a {
  text-decoration: none; }

table {
  border-spacing: 0; }

fieldset, img {
  border: 0; }

address, caption, cite, code, dfn, em, strong, th, var {
  font-weight: normal;
  font-style: normal; }

strong {
  font-weight: bold; }

ol, ul {
  list-style: none;
  margin: 0;
  padding: 0; }
  ol li, ul li {
    min-height: 1px;
    height: auto; }

caption, th {
  text-align: left; }

h1, h2, h3, h4, h5, h6 {
  color: #000;
  font-size: 100%;
  margin: 0;
  padding: 0; }

q:before, q:after {
  content: ''; }

abbr, acronym {
  border: 0; }

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0; }
  *:focus {
    outline: none; }

/* Custom CSS
 * --------------------------------------- */
body {
  color: #000;
  line-height: 1.5;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 1.4rem;
  height: 100%; }

.scroll_y {
  overflow-y: scroll; }

.wrapper {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  overflow: hidden;
  min-height: 100%; }

.container {
  position: relative;
  margin: 0 auto;
  max-width: 1400px;
  padding: 0 15px;
  width: 100%; }
  .container:before {
    content: '';
    display: table; }
  .container:after {
    content: '';
    display: table;
    clear: both; }

.row, .services_list, .course_list_wrap .course_list, .section_form .choose_item, .section_form form, .section_form .form, .section_we_work .steps_list {
  margin: 0 -15px; }
  .row:before, .services_list:before, .course_list_wrap .course_list:before, .section_form .choose_item:before, .section_form form:before, .section_form .form:before, .section_we_work .steps_list:before {
    content: '';
    display: table; }
  .row:after, .services_list:after, .course_list_wrap .course_list:after, .section_form .choose_item:after, .section_form form:after, .section_form .form:after, .section_we_work .steps_list:after {
    content: '';
    display: table;
    clear: both; }

img {
  max-width: 100%; }

p {
  font-size: 1.4rem; }

h1, h2, h3, h4, h5, h6 {
  color: #000;
  font-weight: 500;
  line-height: 1.2; }

h1 {
  font-size: 4rem;
  color: #fff;
  font-weight: 800; }
  @media only screen and (min-width: 768px) {
    h1 {
      font-size: 4.8rem; } }
  @media only screen and (min-width: 992px) {
    h1 {
      font-size: 5.4rem; } }
  @media only screen and (min-width: 1400px) {
    h1 {
      font-size: 6.4rem; } }

h2 {
  position: relative;
  display: inline-block;
  font-weight: 700;
  color: #0e0e0e;
  font-size: 2.8rem;
  padding: 0 0 20px;
  margin: 0 0 20px; }
  @media only screen and (min-width: 768px) {
    h2 {
      font-size: 3.2rem; } }
  @media only screen and (min-width: 992px) {
    h2 {
      padding: 0 0 30px;
      font-size: 3.8rem; } }
  @media only screen and (min-width: 1400px) {
    h2 {
      padding: 0 0 40px;
      font-size: 4.2rem; } }
  h2:before {
    content: '';
    position: absolute;
    height: 3px;
    bottom: 10px;
    width: 40%;
    left: 50%;
    background-color: #0247f8;
    transform: translate(-50%, 0); }
    @media only screen and (min-width: 992px) {
      h2:before {
        bottom: 15px; } }
    @media only screen and (min-width: 1400px) {
      h2:before {
        bottom: 20px; } }

.b_s {
  -webkit-box-shadow: 40px 40px 49px -41px #d3d1d1;
  -moz-box-shadow: 40px 40px 49px -41px #d3d1d1;
  box-shadow: 40px 40px 49px -41px #d3d1d1; }

.text_a_left {
  text-align: center; }
  @media only screen and (min-width: 768px) {
    .text_a_left {
      text-align: left; } }

.text_a_right {
  text-align: center; }
  @media only screen and (min-width: 768px) {
    .text_a_right {
      text-align: right; } }

.text_a_center {
  text-align: center; }

a {
  transition: all 200ms ease-in-out;
  color: #0247f8;
  font-weight: 600; }
  a.btn {
    display: inline-block;
    color: #000;
    font-size: 1.6rem;
    background-color: #fff;
    padding: 10px 20px;
    border: 1px solid transparent; }
    @media only screen and (min-width: 768px) {
      a.btn {
        padding: 12px 30px; } }
    @media only screen and (min-width: 992px) {
      a.btn {
        border: 2px solid transparent;
        padding: 15px 35px; } }
    @media only screen and (min-width: 1400px) {
      a.btn {
        padding: 20px 40px; } }
    a.btn.red {
      background-color: #ef4162;
      color: #fff; }
      a.btn.red:hover {
        border-color: #ef4162; }
    @media only screen and (min-width: 768px) {
      a.btn.small {
        padding: 8px 25px; } }
    @media only screen and (min-width: 992px) {
      a.btn.small {
        padding: 10px 32px; } }
    @media only screen and (min-width: 1400px) {
      a.btn.small {
        padding: 10px 35px; } }
    a.btn:hover {
      color: #fff;
      background-color: transparent;
      border-color: #fff; }
  a.read_more {
    position: relative;
    padding-right: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    display: inline-block;
    color: #0247f8;
    font-size: 1.6rem;
    text-transform: uppercase;
    font-weight: 600; }
    a.read_more + .hidden_more {
      display: none; }
    a.read_more:before, a.read_more:after {
      content: '';
      position: absolute;
      right: 0;
      top: 50%;
      border: 5px solid transparent;
      border-top: 5px solid #0247f8; }
    a.read_more:after {
      border-top: 5px solid #fff;
      top: calc(50% - 2px); }
    a.read_more.active:before, a.read_more.active:after {
      border-bottom: 5px solid #0247f8;
      border-top: 0; }
    a.read_more.active:after {
      border-bottom: 5px solid #fff;
      border-top: 0;
      top: calc(50% + 2px); }

.clearfix, .steps_list, .course_list_wrap .course_list_item_inner .list_time, .section_advantages .advantages_list, .section_program .program_list li .in, .section_lecturer .col_left .socials_list {
  position: relative; }
  .clearfix:before, .steps_list:before, .course_list_wrap .course_list_item_inner .list_time:before, .section_advantages .advantages_list:before, .section_program .program_list li .in:before, .section_lecturer .col_left .socials_list:before {
    content: '';
    display: table; }
  .clearfix:after, .steps_list:after, .course_list_wrap .course_list_item_inner .list_time:after, .section_advantages .advantages_list:after, .section_program .program_list li .in:after, .section_lecturer .col_left .socials_list:after {
    content: '';
    display: table;
    clear: both; }

#to_top {
  display: none;
  position: fixed;
  bottom: 15px;
  right: 15px;
  line-height: 1;
  z-index: 999;
  padding: 6px 13px;
  background: rgba(255, 255, 255, 0.75);
  margin: auto;
  align-items: center; }
  #to_top i {
    font-size: 4rem;
    color: rgba(0, 145, 126, 0.85);
    background: none;
    line-height: 1; }

.logo {
  position: relative;
  display: inline-block;
  z-index: 2;
  max-width: 120px; }
  @media only screen and (min-width: 768px) {
    .logo {
      max-width: 100%; } }
  .logo img {
    display: inline-block;
    width: auto;
    max-width: 100%;
    margin: 0 auto; }

/* Icon 1 */
.btn_menu {
  display: block;
  position: fixed;
  z-index: 99999;
  width: 40px;
  height: 30px;
  top: 15px;
  right: 15px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer; }
  @media only screen and (min-width: 768px) {
    .btn_menu {
      height: 45px;
      top: 30px;
      right: 30px; } }
  @media only screen and (min-width: 1400px) {
    .btn_menu {
      width: 50px;
      top: 40px;
      right: 40px; } }
  .btn_menu span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: #fff;
    border-radius: 6px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75); }
    @media only screen and (min-width: 768px) {
      .btn_menu span {
        height: 5px; } }
    .btn_menu span:nth-child(1) {
      top: 0; }
    .btn_menu span:nth-child(2) {
      top: 12px; }
      @media only screen and (min-width: 1400px) {
        .btn_menu span:nth-child(2) {
          top: 18px; } }
    .btn_menu span:nth-child(3) {
      top: 24px; }
      @media only screen and (min-width: 1400px) {
        .btn_menu span:nth-child(3) {
          top: 36px; } }
  .btn_menu.open span {
    background-color: #000; }
    .btn_menu.open span:nth-child(1) {
      top: 18px;
      -webkit-transform: rotate(135deg);
      -moz-transform: rotate(135deg);
      -o-transform: rotate(135deg);
      transform: rotate(135deg); }
    .btn_menu.open span:nth-child(2) {
      opacity: 0;
      left: -60px; }
    .btn_menu.open span:nth-child(3) {
      top: 18px;
      -webkit-transform: rotate(-135deg);
      -moz-transform: rotate(-135deg);
      -o-transform: rotate(-135deg);
      transform: rotate(-135deg); }

b, strong {
  font-weight: 600; }

.common_s {
  position: relative;
  background-repeat: no-repeat !important;
  background-size: cover;
  background-position: 50% 50%;
  text-align: center;
  padding: 45px 0;
  z-index: 1; }
  @media only screen and (min-width: 768px) {
    .common_s {
      padding: 70px 0; } }
  @media only screen and (min-width: 992px) {
    .common_s {
      padding: 90px 0; } }
  @media only screen and (min-width: 1400px) {
    .common_s {
      padding: 110px 0; } }
  .common_s h2 {
    text-transform: uppercase;
    font-weight: 800; }
  .common_s.overlay_c ul {
    display: block; }
    .common_s.overlay_c ul.steps_list {
      display: flex; }
    .common_s.overlay_c ul li {
      min-height: 1px;
      height: auto; }
  .common_s.overlay_c:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.75);
    z-index: 1; }
  .common_s h2 + p {
    max-width: 80%;
    margin: 0 auto 25px;
    line-height: 1.5; }
  .common_s .container {
    z-index: 2; }

form input[type=number] {
  -moz-appearance: textfield; }
  form input[type=number]::-webkit-outer-spin-button, form input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; }

form input {
  background-color: #f0f0f1;
  border: 0;
  color: #747474;
  padding: 20px; }
  form input:focus {
    outline: none; }
  form input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #747474;
    font-weight: 600;
    font-size: 1.4rem; }
  form input::-moz-placeholder {
    /* Firefox 19+ */
    color: #747474;
    font-weight: 600;
    font-size: 1.4rem; }
  form input:-ms-input-placeholder {
    /* IE 10+ */
    color: #747474;
    font-weight: 600;
    font-size: 1.4rem; }
  form input:-moz-placeholder {
    /* Firefox 18- */
    color: #747474;
    font-weight: 600;
    font-size: 1.4rem; }

.services_list {
  counter-reset: item;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center; }
  .services_list li {
    counter-increment: item;
    color: #000;
    padding: 15px;
    width: 100%;
    text-align: center;
    min-height: 1px; }
    @media only screen and (min-width: 768px) {
      .services_list li {
        text-align: left;
        width: 50%;
        float: left; } }
    @media only screen and (min-width: 992px) {
      .services_list li {
        width: 33.333%; } }
    .services_list li:before {
      content: "" counter(item, decimal-leading-zero) "/";
      counter-increment: li;
      top: -10px;
      display: block;
      margin: 0 0 10px;
      font-size: 1.6rem;
      font-weight: 600;
      text-transform: uppercase; }
    .services_list li .inner_wrap h4 {
      font-size: 1.8rem;
      text-transform: uppercase;
      color: #000;
      font-weight: 700;
      margin: 0 0 10px; }
    .services_list li .inner_wrap p {
      font-weight: 400;
      font-size: 1.5rem;
      margin: 0 0 10px;
      color: #000; }
    .services_list li .inner_wrap .read_more {
      font-weight: 700;
      font-size: 1.3rem;
      text-transform: uppercase;
      margin: 0 0 10px;
      color: #000; }
      .services_list li .inner_wrap .read_more:before {
        top: 44%; }
      .services_list li .inner_wrap .read_more:after {
        border-top: 5px solid #fff;
        top: calc(44% - 2px); }
      .services_list li .inner_wrap .read_more.hidden {
        display: none; }
      .services_list li .inner_wrap .read_more.active:before {
        border-bottom-color: #000; }
      .services_list li .inner_wrap .read_more:before {
        border-top-color: #000; }
    .services_list li .inner_wrap .hidden_more p {
      text-transform: uppercase;
      font-size: 1.3rem; }
      .services_list li .inner_wrap .hidden_more p b, .services_list li .inner_wrap .hidden_more p strong {
        font-weight: 700; }
    .services_list li .inner_wrap .hidden_more .get_cons {
      position: relative;
      font-weight: 700;
      display: inline-block;
      color: #f74667;
      font-size: 1.3rem;
      text-transform: uppercase;
      margin: 0 0 10px; }
      .services_list li .inner_wrap .hidden_more .get_cons i {
        padding-left: 10px; }
        @media only screen and (min-width: 768px) {
          .services_list li .inner_wrap .hidden_more .get_cons i {
            padding-left: 15px; } }
    .services_list li .inner_wrap .hidden_more .a_read_more {
      position: relative;
      display: inline-block;
      font-size: 1.3rem;
      padding-right: 20px;
      color: #000;
      text-transform: uppercase;
      font-weight: 700; }
      .services_list li .inner_wrap .hidden_more .a_read_more:before, .services_list li .inner_wrap .hidden_more .a_read_more:after {
        content: '';
        position: absolute;
        right: 0;
        top: 44%;
        border: 5px solid transparent;
        border-top: 5px solid #000; }
      .services_list li .inner_wrap .hidden_more .a_read_more:after {
        border-top: 5px solid #fff;
        top: calc(44% - 2px); }
      .services_list li .inner_wrap .hidden_more .a_read_more:before, .services_list li .inner_wrap .hidden_more .a_read_more:after {
        border-bottom: 5px solid #000;
        border-top: 0; }
      .services_list li .inner_wrap .hidden_more .a_read_more:after {
        border-bottom: 5px solid #fff;
        border-top: 0;
        top: calc(44% + 2px); }

.steps_list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  counter-reset: item; }
  .steps_list li {
    padding: 0 15px;
    min-height: 1px;
    width: 50%;
    counter-increment: item; }
    @media only screen and (min-width: 768px) {
      .steps_list li {
        float: left;
        width: 33.333%; } }
    @media only screen and (min-width: 992px) {
      .steps_list li {
        width: 33.333%; } }
    @media only screen and (min-width: 1400px) {
      .steps_list li {
        width: 20%; } }
    .steps_list li a {
      height: 100%;
      justify-content: space-between;
      padding: 25px 15px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column; }
      .steps_list li a:before {
        content: counter(item, decimal-leading-zero);
        counter-increment: li;
        display: block;
        margin: 0 0 10px 0;
        color: #000;
        font-size: 1.3rem;
        font-weight: 700; }
      .steps_list li a img {
        max-width: 100%; }
      .steps_list li a h5 {
        padding: 15px 0 0 0;
        font-size: 1.2rem;
        text-transform: uppercase;
        color: #000;
        font-weight: 700; }
      .steps_list li a:hover {
        background-color: #fff;
        -webkit-box-shadow: 0px 0px 70px -8px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 0px 0px 70px -8px rgba(0, 0, 0, 0.75);
        box-shadow: 0px 0px 70px -8px rgba(0, 0, 0, 0.75); }
    .steps_list li .hidden_content {
      display: none; }

.steps_answers {
  width: 100%;
  margin: 0 auto;
  max-width: 1170px;
  padding: 40px 0; }
  @media only screen and (min-width: 768px) {
    .steps_answers {
      padding: 60px 0; } }
  @media only screen and (min-width: 992px) {
    .steps_answers {
      padding: 80px 0; } }
  .steps_answers p {
    color: #000;
    font-weight: 600;
    font-size: 16px;
    padding: 0 0 15px; }
    .steps_answers p:last-of-type {
      padding-bottom: 40px; }
  .steps_answers a.btn {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
    background-color: #f74667;
    border-color: #f74667;
    color: #fff; }
    .steps_answers a.btn:hover {
      background-color: transparent;
      border-color: #f74667;
      color: #f74667; }

.ov_h {
  overflow-y: hidden; }

.header {
  padding: 15px 0;
  position: relative;
  text-align: center; }
  @media only screen and (min-width: 768px) {
    .header {
      padding: 25px 0; } }
  @media only screen and (min-width: 992px) {
    .header {
      padding: 30px 0; } }
  @media only screen and (min-width: 1400px) {
    .header {
      padding: 40px 0; } }
  .header > .container {
    position: static; }
  .header #particles-js {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    max-height: 100%;
    line-height: 0;
    width: 100%;
    background-color: #02003a;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat !important; }
    .header #particles-js canvas {
      height: 100%;
      width: 100%; }
  .header #menu_wrap {
    position: fixed;
    top: 0;
    left: -100%;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    z-index: 11;
    background-color: #f3f2f2;
    transition: all .35s ease-in-out; }
    .header #menu_wrap.show {
      left: 0; }
    .header #menu_wrap .container {
      height: 100%; }
    .header #menu_wrap .logo_menu {
      position: absolute;
      top: 40px;
      left: 15px;
      line-height: 0;
      display: inline-block; }
    .header #menu_wrap .menu_wrap_inner {
      position: relative;
      padding-top: 150px;
      text-align: center; }
      @media only screen and (min-width: 768px) {
        .header #menu_wrap .menu_wrap_inner {
          position: absolute;
          top: 50%;
          left: 0;
          right: 0;
          max-width: 100%;
          padding-top: 0;
          transform: translate(0, -50%);
          display: flex;
          align-items: center;
          flex-direction: row;
          text-align: left; } }
      .header #menu_wrap .menu_wrap_inner h3 {
        text-transform: uppercase;
        color: #e3e2e2;
        font-weight: 700;
        font-size: 14rem;
        display: none; }
        @media only screen and (min-width: 768px) {
          .header #menu_wrap .menu_wrap_inner h3 {
            display: block;
            font-size: 16rem;
            width: 75%; } }
        @media only screen and (min-width: 992px) {
          .header #menu_wrap .menu_wrap_inner h3 {
            font-size: 22rem; } }
        @media only screen and (min-width: 1400px) {
          .header #menu_wrap .menu_wrap_inner h3 {
            font-size: 30rem; } }
      @media only screen and (min-width: 768px) {
        .header #menu_wrap .menu_wrap_inner .menu {
          padding-left: 20px;
          width: 25%; } }
      .header #menu_wrap .menu_wrap_inner .menu li {
        padding: 5px 0; }
        @media only screen and (min-width: 768px) {
          .header #menu_wrap .menu_wrap_inner .menu li {
            padding: 0; } }
        .header #menu_wrap .menu_wrap_inner .menu li a {
          color: #161616;
          font-size: 2rem;
          font-weight: 600; }
          @media only screen and (min-width: 768px) {
            .header #menu_wrap .menu_wrap_inner .menu li a {
              font-size: 2.2rem; } }
          @media only screen and (min-width: 992px) {
            .header #menu_wrap .menu_wrap_inner .menu li a {
              font-size: 2.4rem; } }
          @media only screen and (min-width: 1400px) {
            .header #menu_wrap .menu_wrap_inner .menu li a {
              font-size: 3rem; } }
        .header #menu_wrap .menu_wrap_inner .menu li.active a {
          position: relative;
          color: #0247f8; }
          .header #menu_wrap .menu_wrap_inner .menu li.active a:before {
            content: '';
            width: 20px;
            height: 2px;
            background-color: #0247f8;
            display: inline-block;
            position: absolute;
            left: -30px;
            top: 50%;
            line-height: 0;
            transform: translate(0, -50%); }
        .header #menu_wrap .menu_wrap_inner .menu li:hover a {
          position: relative;
          color: #0247f8; }
          .header #menu_wrap .menu_wrap_inner .menu li:hover a:before {
            content: '';
            width: 20px;
            height: 2px;
            background-color: #0247f8;
            display: inline-block;
            position: absolute;
            left: -30px;
            top: 50%;
            line-height: 0;
            transform: translate(0, -50%); }

.video_wrap {
  position: relative;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  max-width: 100%; }
  .video_wrap .logo {
    display: block;
    margin: 0 auto; }
  .video_wrap #video {
    position: absolute;
    top: 0;
    left: 0;
    font-family: 'object-fit: cover;';
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    height: 100%;
    z-index: 2; }
  .video_wrap_content {
    position: absolute;
    max-height: 100%;
    max-width: 1400px;
    width: 100%;
    left: 50%;
    top: 50%;
    right: auto;
    bottom: auto;
    z-index: 3;
    transform: translate(-50%, -50%);
    text-align: center; }
    .video_wrap_content .choose_links {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      padding: 10px 0 0; }
      @media only screen and (min-width: 768px) {
        .video_wrap_content .choose_links {
          flex-direction: row; } }
      @media only screen and (min-width: 992px) {
        .video_wrap_content .choose_links {
          padding: 20px 0 0; } }
      @media only screen and (min-width: 1400px) {
        .video_wrap_content .choose_links {
          padding: 40px 0 0; } }
      .video_wrap_content .choose_links .btn {
        margin: 10px 0;
        min-width: 80%; }
        @media only screen and (min-width: 768px) {
          .video_wrap_content .choose_links .btn {
            margin: 0 10px;
            min-width: 280px; } }
        @media only screen and (min-width: 992px) {
          .video_wrap_content .choose_links .btn {
            margin: 0 15px;
            min-width: 300px; } }
        @media only screen and (min-width: 1400px) {
          .video_wrap_content .choose_links .btn {
            margin: 0 20px;
            min-width: 320px; } }

.course_list_wrap {
  text-align: center;
  padding: 30px 0; }
  @media only screen and (min-width: 768px) {
    .course_list_wrap {
      padding: 60px 0; } }
  @media only screen and (min-width: 992px) {
    .course_list_wrap {
      padding: 70px 0; } }
  @media only screen and (min-width: 1400px) {
    .course_list_wrap {
      padding: 80px 0; } }
  .course_list_wrap .container {
    padding: 0 30px; }
    @media only screen and (min-width: 768px) {
      .course_list_wrap .container {
        padding: 0 50px; } }
    .course_list_wrap .container .course_list {
      margin: 0 -30px; }
      @media only screen and (min-width: 768px) {
        .course_list_wrap .container .course_list {
          margin: 0 -50px; } }
      .course_list_wrap .container .course_list_item {
        width: 100%;
        padding: 30px; }
        @media only screen and (min-width: 768px) {
          .course_list_wrap .container .course_list_item {
            width: 50%; } }
        @media only screen and (min-width: 992px) {
          .course_list_wrap .container .course_list_item {
            width: 33.333%; } }
        @media only screen and (min-width: 1400px) {
          .course_list_wrap .container .course_list_item {
            width: 33.333%; } }
        .course_list_wrap .container .course_list_item .btn {
          padding: 6px 20px; }
          @media only screen and (min-width: 768px) {
            .course_list_wrap .container .course_list_item .btn {
              padding: 8px 28px; } }
          @media only screen and (min-width: 992px) {
            .course_list_wrap .container .course_list_item .btn {
              border: 2px solid transparent;
              padding: 10px 32px; } }
          @media only screen and (min-width: 1400px) {
            .course_list_wrap .container .course_list_item .btn {
              padding: 12px 36px; } }
  .course_list_wrap p {
    text-align: center; }
  .course_list_wrap .course_list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; }
    @media only screen and (min-width: 1400px) {
      .course_list_wrap .course_list {
        padding: 40px 0 0 0; } }
    .course_list_wrap .course_list_item {
      padding: 15px; }
      @media only screen and (min-width: 768px) {
        .course_list_wrap .course_list_item {
          width: 50%;
          float: left; } }
      @media only screen and (min-width: 992px) {
        .course_list_wrap .course_list_item {
          width: 33.333%; } }
      .course_list_wrap .course_list_item.current .start_date {
        color: #f74667; }
        .course_list_wrap .course_list_item.current .start_date:before {
          background-color: #f74667; }
      .course_list_wrap .course_list_item_inner {
        position: relative;
        background-color: #f5f5f5;
        padding: 40px 30px;
        height: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: baseline;
        -ms-flex-align: baseline;
        align-items: baseline;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between; }
        .course_list_wrap .course_list_item_inner:before, .course_list_wrap .course_list_item_inner:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 3px;
          height: 10%;
          background-color: #0247f8; }
        .course_list_wrap .course_list_item_inner:after {
          height: 33.333%;
          top: auto;
          left: auto;
          right: 0;
          bottom: 0; }
        .course_list_wrap .course_list_item_inner .list_time {
          position: absolute;
          top: -25px;
          right: -28px; }
          .course_list_wrap .course_list_item_inner .list_time li {
            background-color: #f35472;
            width: 50px;
            height: 50px;
            border-radius: 4px;
            color: #fff;
            float: left;
            margin: 0 5px;
            font-size: 1.1rem; }
            .course_list_wrap .course_list_item_inner .list_time li span {
              font-weight: 600;
              display: block;
              font-size: 1.6rem; }
              @media only screen and (min-width: 768px) {
                .course_list_wrap .course_list_item_inner .list_time li span {
                  font-size: 1.8rem; } }
              @media only screen and (min-width: 992px) {
                .course_list_wrap .course_list_item_inner .list_time li span {
                  font-size: 2rem; } }
        .course_list_wrap .course_list_item_inner .start_date {
          text-align: left;
          color: #000;
          font-weight: 600;
          font-size: 1.4rem;
          position: relative;
          margin: 0 0 10px; }
          .course_list_wrap .course_list_item_inner .start_date:before {
            content: '';
            width: 20px;
            height: 1px;
            background-color: #000;
            vertical-align: middle;
            display: inline-block;
            margin: 0 15px 2px 0; }
        .course_list_wrap .course_list_item_inner .spec_name {
          text-align: left;
          color: #000;
          font-size: 2rem;
          text-transform: uppercase;
          font-weight: 800; }
          @media only screen and (min-width: 768px) {
            .course_list_wrap .course_list_item_inner .spec_name {
              font-size: 2rem; } }
          @media only screen and (min-width: 992px) {
            .course_list_wrap .course_list_item_inner .spec_name {
              font-size: 2.8rem; } }
        .course_list_wrap .course_list_item_inner .price_tag {
          text-align: left;
          color: #000;
          font-weight: 700;
          font-size: 2rem;
          margin: 0 0 10px; }
          @media only screen and (min-width: 768px) {
            .course_list_wrap .course_list_item_inner .price_tag {
              font-size: 2.4rem;
              margin: 0 0 20px; } }
          @media only screen and (min-width: 992px) {
            .course_list_wrap .course_list_item_inner .price_tag {
              font-size: 2.8rem;
              margin: 0 0 30px; } }
        .course_list_wrap .course_list_item_inner .schedule_list {
          margin: 0 0 15px;
          text-align: left; }
          .course_list_wrap .course_list_item_inner .schedule_list li {
            color: #000;
            font-size: 1.4rem;
            font-weight: 400; }
        .course_list_wrap .course_list_item_inner .btn {
          display: inline-block;
          color: #fff;
          background-color: #0247f8;
          font-size: 1.4rem; }
          @media only screen and (min-width: 768px) {
            .course_list_wrap .course_list_item_inner .btn {
              font-size: 1.5rem; } }
          .course_list_wrap .course_list_item_inner .btn:hover {
            color: #0247f8;
            border-color: #0247f8;
            background-color: transparent; }

.banner {
  position: relative;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat !important;
  padding: 80px 0;
  text-align: center; }
  .banner.overlay_c:before {
    content: '';
    position: absolute;
    background-color: rgba(81, 80, 80, 0.5);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }
  .banner .container {
    z-index: 1; }
  .banner h3 {
    font-size: 4.6rem;
    color: #fff;
    font-weight: 700;
    margin: 0 0 20px; }

.footer {
  background-color: #161616;
  padding: 15px 0 10px 0; }
  @media only screen and (min-width: 768px) {
    .footer {
      padding: 40px 0 10px 0; } }
  .footer .col {
    padding: 15px;
    min-height: 1px;
    text-align: center; }
    @media only screen and (min-width: 768px) {
      .footer .col {
        text-align: left;
        width: 33.333%;
        float: left;
        padding: 0 15px; } }
    .footer .col:nth-child(2) {
      text-align: center; }
    .footer .col:nth-child(3) {
      text-align: center; }
      @media only screen and (min-width: 768px) {
        .footer .col:nth-child(3) {
          text-align: right; } }
  .footer .logo_footer {
    display: block; }
    .footer .logo_footer img {
      max-width: 100%; }
  .footer .m_list li {
    display: inline-block;
    padding: 0 15px; }
    @media only screen and (min-width: 992px) {
      .footer .m_list li {
        padding: 0 30px; } }
    .footer .m_list li a {
      display: block;
      text-transform: uppercase;
      font-size: 1.6rem;
      font-weight: 600;
      color: #fff; }
      .footer .m_list li a:hover {
        color: #0247f8; }
  .footer .contacts {
    display: inline-block;
    text-align: left; }
    .footer .contacts p {
      padding: 5px 0; }
    .footer .contacts span {
      margin: 0 10px 0 0;
      text-transform: uppercase; }
    .footer .contacts span, .footer .contacts a {
      display: inline-block;
      font-weight: 600;
      font-size: 1.6rem;
      color: #fff; }
    .footer .contacts a:hover {
      color: #0247f8; }
  .footer .copy {
    color: #fff;
    font-size: 1.4rem;
    text-align: center;
    font-weight: 400; }

.section_source {
  padding: 60px 0;
  text-align: center; }
  @media only screen and (min-width: 768px) {
    .section_source {
      text-align: left;
      padding: 100px 0; } }
  @media only screen and (min-width: 992px) {
    .section_source {
      padding: 140px 0; } }
  @media only screen and (min-width: 1400px) {
    .section_source {
      padding: 170px 0 200px; } }
  .section_source .col_inner {
    min-height: 1px;
    padding: 15px; }
    @media only screen and (min-width: 768px) {
      .section_source .col_inner {
        float: left;
        padding: 0 15px; } }
    @media only screen and (min-width: 768px) {
      .section_source .col_inner:first-child {
        width: 43%; } }
    @media only screen and (min-width: 768px) {
      .section_source .col_inner:last-child {
        width: 57%; } }
    .section_source .col_inner_left {
      background-color: transparent; }
    .section_source .col_inner_right {
      position: relative;
      background-color: #f5f5f5;
      padding: 15px; }
      @media only screen and (min-width: 768px) {
        .section_source .col_inner_right {
          padding: 50px 70px; } }
      @media only screen and (min-width: 992px) {
        .section_source .col_inner_right {
          padding: 80px 100px; } }
      @media only screen and (min-width: 1400px) {
        .section_source .col_inner_right {
          padding: 100px 140px; } }
      .section_source .col_inner_right:before {
        content: '';
        position: absolute;
        width: 3px;
        height: 50px;
        background-color: #0247f8;
        display: none; }
        @media only screen and (min-width: 768px) {
          .section_source .col_inner_right:before {
            position: absolute;
            display: block;
            left: 50px;
            top: 56px;
            width: 3px;
            height: 50px; } }
        @media only screen and (min-width: 992px) {
          .section_source .col_inner_right:before {
            left: 64px;
            top: 95px; } }
        @media only screen and (min-width: 1400px) {
          .section_source .col_inner_right:before {
            left: 110px;
            top: 107px; } }
      .section_source .col_inner_right .helper_lines {
        position: relative; }
        .section_source .col_inner_right .helper_lines:before, .section_source .col_inner_right .helper_lines:after {
          content: '';
          display: none;
          background-color: #0247f8; }
          @media only screen and (min-width: 768px) {
            .section_source .col_inner_right .helper_lines:before, .section_source .col_inner_right .helper_lines:after {
              position: absolute;
              display: block;
              width: 3px;
              height: 90px;
              right: -30px;
              top: -78px; } }
          @media only screen and (min-width: 992px) {
            .section_source .col_inner_right .helper_lines:before, .section_source .col_inner_right .helper_lines:after {
              right: -30px;
              height: 120px;
              top: -110px; } }
          @media only screen and (min-width: 1400px) {
            .section_source .col_inner_right .helper_lines:before, .section_source .col_inner_right .helper_lines:after {
              right: -30px;
              top: -154px; } }
        .section_source .col_inner_right .helper_lines:after {
          left: auto;
          top: auto; }
          @media only screen and (min-width: 768px) {
            .section_source .col_inner_right .helper_lines:after {
              right: 56px;
              bottom: -68px; } }
          @media only screen and (min-width: 992px) {
            .section_source .col_inner_right .helper_lines:after {
              right: 100px;
              bottom: -120px; } }
          @media only screen and (min-width: 1400px) {
            .section_source .col_inner_right .helper_lines:after {
              right: 110px;
              bottom: -168px; } }
      .section_source .col_inner_right p {
        margin-bottom: 20px;
        font-size: 1.4rem; }
        @media only screen and (min-width: 768px) {
          .section_source .col_inner_right p {
            font-size: 1.2rem; } }
        @media only screen and (min-width: 992px) {
          .section_source .col_inner_right p {
            font-size: 1.6rem; } }
      .section_source .col_inner_right .btn:hover {
        color: #ef4162; }
    .section_source .col_inner h4 {
      color: #0247f8;
      font-weight: 800;
      text-transform: uppercase;
      line-height: 1.3;
      font-size: 2.8rem; }
      @media only screen and (min-width: 768px) {
        .section_source .col_inner h4 {
          font-size: 3.2rem; } }
      @media only screen and (min-width: 992px) {
        .section_source .col_inner h4 {
          font-size: 3.6rem; } }
      @media only screen and (min-width: 1400px) {
        .section_source .col_inner h4 {
          font-size: 4rem; } }
    .section_source .col_inner p {
      font-weight: 400;
      margin: 0 0 10px; }
      .section_source .col_inner p b, .section_source .col_inner p strong {
        font-weight: 600; }
    .section_source .col_inner .start_date {
      position: relative;
      color: #f74667;
      font-size: 1.6rem;
      font-weight: 600;
      padding-left: 40px;
      margin: 0 0 25px;
      display: inline-block; }
      @media only screen and (min-width: 768px) {
        .section_source .col_inner .start_date {
          display: block; } }
      .section_source .col_inner .start_date:before {
        content: '';
        width: 30px;
        height: 1px;
        background-color: #000;
        position: absolute;
        left: 0;
        top: 50%;
        -webkit-transform: translate(0, -50%);
        -moz-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        -o-transform: translate(0, -50%);
        transform: translate(0, -50%); }
    .section_source .col_inner ul {
      margin: 0 0 25px; }
      .section_source .col_inner ul.labels {
        font-size: 0; }
        .section_source .col_inner ul.labels li {
          font-size: 1rem;
          display: inline-block;
          padding: 4px 6px;
          background-color: #d4dbfd;
          margin: 0 8px 0 0;
          font-weight: 700; }
          .section_source .col_inner ul.labels li:last-child {
            margin: 0; }
      .section_source .col_inner ul.desc_list li {
        display: inline-block;
        width: 60px;
        height: 60px;
        margin: 0 10px 0 0; }
        .section_source .col_inner ul.desc_list li:last-child {
          margin: 0; }
        @media only screen and (min-width: 768px) {
          .section_source .col_inner ul.desc_list li {
            width: 75px;
            height: 75px; } }
        @media only screen and (min-width: 992px) {
          .section_source .col_inner ul.desc_list li {
            width: 90px;
            height: 90px; } }
        @media only screen and (min-width: 1400px) {
          .section_source .col_inner ul.desc_list li {
            width: 105px;
            height: 105px; } }
        .section_source .col_inner ul.desc_list li .desc_list_wr {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          flex-direction: column;
          justify-content: center;
          width: 100%;
          height: 100%;
          -webkit-box-shadow: 0 0 50px 10px #d3d1d1;
          -moz-box-shadow: 0 0 50px 10px #d3d1d1;
          box-shadow: 0 0 50px 10px #d3d1d1; }
          .section_source .col_inner ul.desc_list li .desc_list_wr .n {
            font-weight: 600;
            font-size: 2rem; }
            @media only screen and (min-width: 768px) {
              .section_source .col_inner ul.desc_list li .desc_list_wr .n {
                font-size: 2.4rem; } }
            @media only screen and (min-width: 992px) {
              .section_source .col_inner ul.desc_list li .desc_list_wr .n {
                font-size: 2.8rem; } }
            @media only screen and (min-width: 1400px) {
              .section_source .col_inner ul.desc_list li .desc_list_wr .n {
                font-size: 3rem; } }
          .section_source .col_inner ul.desc_list li .desc_list_wr .w {
            font-weight: 400;
            font-size: 1.4rem; }
            @media only screen and (min-width: 768px) {
              .section_source .col_inner ul.desc_list li .desc_list_wr .w {
                font-size: 1.6rem; } }
            @media only screen and (min-width: 992px) {
              .section_source .col_inner ul.desc_list li .desc_list_wr .w {
                font-size: 1.8rem; } }
            @media only screen and (min-width: 1400px) {
              .section_source .col_inner ul.desc_list li .desc_list_wr .w {
                font-size: 2rem; } }
    .section_source .col_inner .price {
      font-weight: 700;
      margin: 0 0 35px;
      font-size: 2.6rem; }
      @media only screen and (min-width: 768px) {
        .section_source .col_inner .price {
          font-size: 2.8rem; } }
      @media only screen and (min-width: 992px) {
        .section_source .col_inner .price {
          font-size: 3rem; } }
      @media only screen and (min-width: 1400px) {
        .section_source .col_inner .price {
          font-size: 3.4rem; } }

.section_info {
  position: relative; }
  .section_info .container {
    z-index: 2; }
  .section_info .row, .section_info .services_list, .section_info .course_list_wrap .course_list, .course_list_wrap .section_info .course_list, .section_info .section_form .choose_item, .section_form .section_info .choose_item, .section_info .section_form form, .section_form .section_info form, .section_info .section_form .form, .section_form .section_info .form, .section_info .section_we_work .steps_list, .section_we_work .section_info .steps_list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column; }
    @media only screen and (min-width: 768px) {
      .section_info .row, .section_info .services_list, .section_info .course_list_wrap .course_list, .course_list_wrap .section_info .course_list, .section_info .section_form .choose_item, .section_form .section_info .choose_item, .section_info .section_form form, .section_form .section_info form, .section_info .section_form .form, .section_form .section_info .form, .section_info .section_we_work .steps_list, .section_we_work .section_info .steps_list {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row; } }
  .section_info .col_left {
    text-align: left;
    min-height: 1px;
    padding: 15px; }
    @media only screen and (min-width: 768px) {
      .section_info .col_left {
        width: 55%;
        padding: 0 15px;
        float: left; } }
    .section_info .col_left img {
      width: 100%;
      max-width: 100%;
      display: block; }
  .section_info .col_right {
    text-align: left;
    min-height: 1px;
    padding: 15px; }
    @media only screen and (min-width: 768px) {
      .section_info .col_right {
        width: 45%;
        padding: 0 15px 0 45px;
        float: left; } }
    .section_info .col_right h5 {
      margin: 0 0 15px 0;
      font-weight: 700;
      height: auto;
      color: #000;
      min-height: 0;
      font-size: 1.6rem; }
      @media only screen and (min-width: 768px) {
        .section_info .col_right h5 {
          margin: 0 0 25px 0;
          font-size: 1.8rem; } }
      @media only screen and (min-width: 992px) {
        .section_info .col_right h5 {
          font-size: 2rem; } }
    .section_info .col_right ul {
      display: block; }
      @media only screen and (min-width: 768px) {
        .section_info .col_right ul {
          margin: 0 0 0 -15px; } }
      .section_info .col_right ul li {
        position: relative;
        font-weight: 400;
        margin: 0 0 10px 0;
        height: auto;
        min-height: 0;
        font-size: 1.4rem;
        padding-left: 15px; }
        @media only screen and (min-width: 992px) {
          .section_info .col_right ul li {
            font-size: 1.6rem; } }
        .section_info .col_right ul li:last-child {
          margin: 0; }
        .section_info .col_right ul li:before {
          content: '';
          width: 6px;
          height: 6px;
          display: inline-block;
          vertical-align: middle;
          border-radius: 50%;
          background-color: #0247f8;
          position: absolute;
          left: 0;
          top: 50%;
          -webkit-transform: translate(0, -50%);
          -ms-transform: translate(0, -50%);
          transform: translate(0, -50%); }

.section_details {
  background-color: #fafafa; }
  .section_details h2 + p {
    text-align: left;
    max-width: 100%;
    margin: 0 0 20px 0; }
  .section_details p {
    font-weight: 400;
    font-size: 1.6rem;
    text-align: left;
    margin: 0 0 20px 0;
    display: none; }
    .section_details p:last-child {
      margin: 0; }
    .section_details p:first-of-type {
      display: block !important; }
      .section_details p:first-of-type + p {
        display: block !important; }
  .section_details .r_m {
    text-align: left; }
  @media only screen and (min-width: 768px) {
    .section_details .read_more {
      float: left; } }

.section_advantages .advantages_list {
  padding-top: 30px;
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  flex-direction: row;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center; }
  .section_advantages .advantages_list li {
    display: flex;
    flex-direction: column;
    float: left;
    width: 50%;
    min-height: 1px;
    padding: 15px 15px 80px 15px; }
    @media only screen and (min-width: 768px) {
      .section_advantages .advantages_list li {
        padding-bottom: 90px;
        width: 33.333%; } }
    @media only screen and (min-width: 992px) {
      .section_advantages .advantages_list li {
        padding-bottom: 100px;
        width: 16.6667%; } }
    .section_advantages .advantages_list li div {
      flex-direction: column;
      position: relative;
      align-items: center;
      padding: 30px;
      height: 100%;
      display: flex;
      background-color: #fff;
      -webkit-box-shadow: 0 0 50px 10px #d3d1d1;
      -moz-box-shadow: 0 0 50px 10px #d3d1d1;
      box-shadow: 0 0 50px 10px #d3d1d1; }
      .section_advantages .advantages_list li div img {
        max-width: 100%;
        margin: auto; }
    .section_advantages .advantages_list li h5 {
      position: absolute;
      top: 100%;
      font-size: 14px;
      font-weight: 600;
      color: #000;
      line-height: 1.5;
      left: 0;
      right: 0;
      padding: 15px 5px 0; }
      @media only screen and (min-width: 768px) {
        .section_advantages .advantages_list li h5 {
          font-size: 16px; } }

.section_program {
  overflow: hidden; }
  .section_program .program_list {
    position: relative;
    text-align: left;
    max-width: 820px;
    margin: 0 auto; }
    .section_program .program_list:before {
      content: '';
      position: absolute;
      top: 30px;
      left: 22%;
      bottom: 100px;
      width: 1px;
      background-color: #dadada;
      z-index: 1; }
      @media only screen and (min-width: 768px) {
        .section_program .program_list:before {
          left: 12%; } }
    .section_program .program_list li.last .img:after {
      content: '';
      position: absolute;
      width: 1px;
      height: 9999px;
      background-color: #fff;
      right: -1%;
      z-index: 21;
      top: 49px; }
    .section_program .program_list li.last .in .cont_inner {
      border-bottom: 0; }
    .section_program .program_list li .in .img {
      position: relative;
      width: 22%;
      float: left;
      padding-right: 30px; }
      @media only screen and (min-width: 768px) {
        .section_program .program_list li .in .img {
          width: 12%; } }
      .section_program .program_list li .in .img:before {
        content: '';
        position: absolute;
        right: -5px;
        top: 50%;
        width: 5px;
        border-radius: 50%;
        height: 5px;
        border: 2px solid #0247f8;
        background-color: #fff;
        -webkit-transform: translate(0, -50%);
        -moz-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        -o-transform: translate(0, -50%);
        transform: translate(0, -50%);
        -webkit-transition: all 200ms ease-in-out;
        -moz-transition: all 200ms ease-in-out;
        -ms-transition: all 200ms ease-in-out;
        -o-transition: all 200ms ease-in-out;
        transition: all 200ms ease-in-out;
        z-index: 2; }
      .section_program .program_list li .in .img img {
        max-width: 60px;
        width: auto; }
      .section_program .program_list li .in .img.active:before {
        background-color: #0247f8;
        -webkit-box-shadow: 0px 0px 0px 8px #9ecdfd;
        -moz-box-shadow: 0px 0px 0px 8px #9ecdfd;
        box-shadow: 0px 0px 0px 8px #9ecdfd; }
        @media only screen and (min-width: 768px) {
          .section_program .program_list li .in .img.active:before {
            -webkit-box-shadow: 0px 0px 0px 15px #9ecdfd;
            -moz-box-shadow: 0px 0px 0px 15px #9ecdfd;
            box-shadow: 0px 0px 0px 15px #9ecdfd; } }
    .section_program .program_list li .in .cont {
      width: 78%;
      float: left;
      padding-left: 30px;
      -webkit-transform: translate(0, -25px);
      -moz-transform: translate(0, -25px);
      -ms-transform: translate(0, -25px);
      -o-transform: translate(0, -25px);
      transform: translate(0, -25px); }
      @media only screen and (min-width: 768px) {
        .section_program .program_list li .in .cont {
          width: 88%;
          -webkit-transform: translate(0, -25px);
          -moz-transform: translate(0, -25px);
          -ms-transform: translate(0, -25px);
          -o-transform: translate(0, -25px);
          transform: translate(0, -25px); } }
      .section_program .program_list li .in .cont_inner {
        padding: 20px 0;
        border-bottom: 1px dashed #dadada; }
      .section_program .program_list li .in .cont .module {
        color: #0247f8;
        font-size: 1.2rem;
        font-weight: 700;
        text-transform: uppercase; }
      .section_program .program_list li .in .cont h5 {
        color: #000;
        text-transform: uppercase;
        font-size: 1.8rem;
        font-weight: 600;
        padding: 5px 0; }
        @media only screen and (min-width: 768px) {
          .section_program .program_list li .in .cont h5 {
            font-size: 2rem; } }
      .section_program .program_list li .in .cont .themes_list_wrap span {
        position: relative;
        display: inline-block;
        padding: 5px 20px 5px 0;
        font-size: 1.6rem;
        font-weight: 600;
        cursor: pointer; }
        .section_program .program_list li .in .cont .themes_list_wrap span.down:before, .section_program .program_list li .in .cont .themes_list_wrap span.down:after {
          content: '';
          position: absolute;
          right: 0;
          top: 50%;
          border: 5px solid transparent;
          border-top: 5px solid black; }
        .section_program .program_list li .in .cont .themes_list_wrap span.down:after {
          border-top: 5px solid white;
          top: calc(50% - 2px); }
        .section_program .program_list li .in .cont .themes_list_wrap span.down.top:before, .section_program .program_list li .in .cont .themes_list_wrap span.down.top:after {
          border-bottom: 5px solid black;
          border-top: 0; }
        .section_program .program_list li .in .cont .themes_list_wrap span.down.top:after {
          border-bottom: 5px solid white;
          border-top: 0;
          top: calc(50% + 2px); }
      .section_program .program_list li .in .cont .themes_list_wrap .hidden_list {
        display: none; }
        .section_program .program_list li .in .cont .themes_list_wrap .hidden_list li {
          font-size: 1.2rem;
          font-weight: 400; }
          .section_program .program_list li .in .cont .themes_list_wrap .hidden_list li:before {
            content: '- ';
            display: inline-block;
            padding-right: 3px; }

.section_questions_answers {
  text-align: left;
  background-attachment: fixed; }
  @media only screen and (min-width: 768px) {
    .section_questions_answers {
      background-attachment: inherit; } }
  .section_questions_answers.overlay_c:before {
    background-color: rgba(230, 239, 246, 0.9); }
  .section_questions_answers .col_left {
    min-height: 1px;
    padding: 0 15px; }
    @media only screen and (min-width: 768px) {
      .section_questions_answers .col_left {
        float: left;
        width: 50%; } }
    .section_questions_answers .col_left .tabs {
      padding-top: 30px; }
      @media only screen and (min-width: 768px) {
        .section_questions_answers .col_left .tabs {
          padding-top: 50px; } }
      @media only screen and (min-width: 992px) {
        .section_questions_answers .col_left .tabs {
          padding-top: 70px; } }
      .section_questions_answers .col_left .tabs_item {
        display: block;
        cursor: pointer;
        min-height: 1px;
        font-weight: 700;
        font-size: 2rem;
        position: relative;
        margin: 0 0 30px 0;
        padding-left: 30px;
        color: #c7c5c5; }
        .section_questions_answers .col_left .tabs_item:before {
          content: '';
          position: absolute;
          left: 0;
          top: -7px;
          bottom: -7px;
          width: 4px;
          background-color: #c7c5c5; }
        .section_questions_answers .col_left .tabs_item.active {
          color: #000; }
          .section_questions_answers .col_left .tabs_item.active:before {
            background-color: #f74667; }
  .section_questions_answers .col_right {
    min-height: 1px;
    padding: 0 15px; }
    @media only screen and (min-width: 768px) {
      .section_questions_answers .col_right {
        float: left;
        width: 50%; } }
    .section_questions_answers .col_right .content_tab {
      display: none; }
      .section_questions_answers .col_right .content_tab.opened {
        display: block; }
      .section_questions_answers .col_right .content_tab ul li {
        border-bottom: 1px solid #d5d5d5;
        padding: 10px 15px; }
        .section_questions_answers .col_right .content_tab ul li .title {
          position: relative;
          display: block;
          cursor: pointer;
          font-weight: 600;
          font-size: 1.8rem;
          color: #000;
          padding-right: 30px; }
          .section_questions_answers .col_right .content_tab ul li .title:after {
            content: '+';
            position: absolute;
            top: 5px;
            right: 0;
            font-size: 2.4rem;
            line-height: .5;
            color: #f74667;
            -webkit-transition: all 200ms ease-in-out;
            -moz-transition: all 200ms ease-in-out;
            -ms-transition: all 200ms ease-in-out;
            -o-transition: all 200ms ease-in-out;
            transition: all 200ms ease-in-out; }
            @media only screen and (min-width: 768px) {
              .section_questions_answers .col_right .content_tab ul li .title:after {
                font-size: 3rem; } }
          .section_questions_answers .col_right .content_tab ul li .title.active {
            color: #f74667; }
            .section_questions_answers .col_right .content_tab ul li .title.active:after {
              -webkit-transform: rotate(45deg);
              -moz-transform: rotate(45deg);
              -ms-transform: rotate(45deg);
              -o-transform: rotate(45deg);
              transform: rotate(45deg); }
        .section_questions_answers .col_right .content_tab ul li .content {
          display: none;
          font-weight: 400;
          padding: 10px 0; }
  .section_questions_answers h3 {
    font-weight: 700;
    color: #000;
    font-size: 2.4rem;
    margin: 0 0 10px 0; }
    @media only screen and (min-width: 768px) {
      .section_questions_answers h3 {
        font-size: 2.8rem; } }
    @media only screen and (min-width: 992px) {
      .section_questions_answers h3 {
        font-size: 3.2rem; } }
  .section_questions_answers p {
    font-size: 1.8rem;
    font-weight: 400;
    color: #000; }

.section_logos h3 {
  text-transform: uppercase;
  font-weight: 700;
  font-family: Myriad Pro, sans-serif;
  letter-spacing: -.5px;
  color: #000;
  font-size: 2rem;
  padding-bottom: 30px; }
  @media only screen and (min-width: 768px) {
    .section_logos h3 {
      font-size: 2.2rem;
      padding-bottom: 60px; } }
  @media only screen and (min-width: 992px) {
    .section_logos h3 {
      padding-bottom: 70px;
      font-size: 2.4rem; } }
  @media only screen and (min-width: 1400px) {
    .section_logos h3 {
      padding-bottom: 80px; } }

.section_logos .logos_wrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -15px; }
  .section_logos .logos_wrap_item {
    padding: 15px; }
    @media only screen and (min-width: 768px) {
      .section_logos .logos_wrap_item {
        padding: 15px 25px; } }
    @media only screen and (min-width: 992px) {
      .section_logos .logos_wrap_item {
        padding: 15px 30px; } }
    @media only screen and (min-width: 1400px) {
      .section_logos .logos_wrap_item {
        padding: 15px 45px; } }

.section_lecturer {
  background-color: #fafafa; }
  @media only screen and (min-width: 768px) {
    .section_lecturer .row, .section_lecturer .services_list, .section_lecturer .course_list_wrap .course_list, .course_list_wrap .section_lecturer .course_list, .section_lecturer .section_form .choose_item, .section_form .section_lecturer .choose_item, .section_lecturer .section_form form, .section_form .section_lecturer form, .section_lecturer .section_form .form, .section_form .section_lecturer .form, .section_lecturer .section_we_work .steps_list, .section_we_work .section_lecturer .steps_list {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: stretch;
      -ms-flex-align: stretch;
      align-items: stretch; } }
  .section_lecturer .col_left {
    min-height: 1px;
    position: relative; }
    @media only screen and (min-width: 768px) {
      .section_lecturer .col_left {
        float: left;
        padding: 0 15px;
        width: 56%;
        text-align: left; } }
    .section_lecturer .col_left .in {
      background-color: #f1f1f1;
      padding: 15px;
      height: 100%; }
      @media only screen and (min-width: 768px) {
        .section_lecturer .col_left .in {
          padding: 35px 30px 35px 30px; } }
      @media only screen and (min-width: 992px) {
        .section_lecturer .col_left .in {
          padding: 45px 120px 45px 80px; } }
      @media only screen and (min-width: 1400px) {
        .section_lecturer .col_left .in {
          padding: 55px 150px 55px 100px; } }
      .section_lecturer .col_left .in_helpers {
        position: relative;
        width: 100%;
        height: 100%; }
        .section_lecturer .col_left .in_helpers:before, .section_lecturer .col_left .in_helpers:after {
          content: '';
          position: absolute;
          width: 3px;
          background-color: #0247f8;
          height: 25px;
          top: 47px;
          left: -20px; }
          @media only screen and (min-width: 768px) {
            .section_lecturer .col_left .in_helpers:before, .section_lecturer .col_left .in_helpers:after {
              top: 47px;
              left: -20px;
              height: 30px; } }
          @media only screen and (min-width: 992px) {
            .section_lecturer .col_left .in_helpers:before, .section_lecturer .col_left .in_helpers:after {
              top: 47px;
              left: -20px;
              height: 40px; } }
          @media only screen and (min-width: 1400px) {
            .section_lecturer .col_left .in_helpers:before, .section_lecturer .col_left .in_helpers:after {
              top: 47px;
              left: -20px;
              height: 50px; } }
        .section_lecturer .col_left .in_helpers:after {
          height: 80px;
          left: auto;
          right: -20px;
          top: auto;
          bottom: -40px; }
          @media only screen and (min-width: 768px) {
            .section_lecturer .col_left .in_helpers:after {
              height: 110px;
              bottom: -80px; } }
          @media only screen and (min-width: 992px) {
            .section_lecturer .col_left .in_helpers:after {
              height: 130px;
              bottom: -100px; } }
          @media only screen and (min-width: 1400px) {
            .section_lecturer .col_left .in_helpers:after {
              height: 160px;
              bottom: -120px; } }
      .section_lecturer .col_left .in .title {
        font-weight: 700;
        margin: 0 0 10px; }
        .section_lecturer .col_left .in .title:before {
          content: '';
          display: inline-block;
          margin-right: 10px;
          background-color: #000;
          height: 1px;
          width: 20px;
          vertical-align: middle; }
          @media only screen and (min-width: 768px) {
            .section_lecturer .col_left .in .title:before {
              margin-right: 15px;
              width: 25px; } }
      .section_lecturer .col_left .in .name {
        font-weight: 800;
        text-transform: uppercase;
        font-size: 2.6rem;
        color: #0247f8;
        margin: 0 0 20px 0; }
        @media only screen and (min-width: 768px) {
          .section_lecturer .col_left .in .name {
            font-size: 3.2rem; } }
        @media only screen and (min-width: 992px) {
          .section_lecturer .col_left .in .name {
            font-size: 3.6rem; } }
        @media only screen and (min-width: 1400px) {
          .section_lecturer .col_left .in .name {
            font-size: 4rem; } }
      .section_lecturer .col_left .in .description {
        color: #515151; }
        .section_lecturer .col_left .in .description p {
          color: #515151;
          font-weight: 400;
          font-size: 1.4rem;
          margin: 0 0 20px; }
    .section_lecturer .col_left .socials_list {
      text-align: center;
      position: static;
      padding: 15px 0; }
      @media only screen and (min-width: 768px) {
        .section_lecturer .col_left .socials_list {
          text-align: left;
          padding: 0;
          position: absolute;
          bottom: -40px;
          right: 100px; } }
      @media only screen and (min-width: 992px) {
        .section_lecturer .col_left .socials_list {
          bottom: -40px;
          right: 120px; } }
      @media only screen and (min-width: 1400px) {
        .section_lecturer .col_left .socials_list {
          bottom: -40px;
          right: 140px; } }
      .section_lecturer .col_left .socials_list li {
        padding: 0 15px;
        display: inline-block; }
        @media only screen and (min-width: 768px) {
          .section_lecturer .col_left .socials_list li {
            float: left; } }
        .section_lecturer .col_left .socials_list li a {
          text-transform: uppercase;
          font-weight: 700;
          font-size: 1.4rem;
          color: #000; }
          .section_lecturer .col_left .socials_list li a:hover {
            color: #0247f8; }
  .section_lecturer .col_right {
    min-height: 1px; }
    @media only screen and (min-width: 768px) {
      .section_lecturer .col_right {
        float: left;
        padding: 0 15px;
        width: 44%;
        text-align: right; } }
    .section_lecturer .col_right .in {
      position: relative;
      display: inline-block;
      background-color: #ebf4f1;
      max-width: 430px;
      margin: 0 0 0 auto;
      height: 100%; }
      .section_lecturer .col_right .in .img {
        text-align: right;
        font-size: 0; }
        .section_lecturer .col_right .in .img img {
          display: inline-block;
          max-width: 100%; }
      .section_lecturer .col_right .in .title {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        font-size: 1.8rem;
        font-weight: 600;
        max-width: 100%;
        background-color: #fff;
        padding: 10px 15px;
        text-align: center; }
        .section_lecturer .col_right .in .title:before {
          content: '';
          display: inline-block;
          margin-right: 10px;
          background-color: #000;
          height: 1px;
          width: 20px;
          vertical-align: middle; }
          @media only screen and (min-width: 768px) {
            .section_lecturer .col_right .in .title:before {
              margin-right: 15px;
              width: 25px; } }

.section_form .choose_item {
  text-align: left;
  counter-reset: item;
  margin: 0 0 30px;
  padding-top: 30px; }
  .section_form .choose_item li {
    counter-increment: item;
    cursor: pointer;
    position: relative;
    color: #dadada;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    float: left;
    padding-left: 40px;
    font-size: 1.6rem;
    font-weight: 600;
    margin-right: 15px; }
    @media only screen and (min-width: 768px) {
      .section_form .choose_item li {
        padding-left: 50px;
        min-height: 40px;
        margin-right: 20px; } }
    @media only screen and (min-width: 992px) {
      .section_form .choose_item li {
        margin-right: 40px; } }
    .section_form .choose_item li:before {
      content: counter(item, decimal-leading-zero);
      counter-increment: li;
      position: absolute;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      color: #fff;
      font-size: 1.4rem;
      font-weight: 400;
      background-color: #dadada;
      border-radius: 50%;
      left: 0;
      width: 35px;
      height: 35px; }
      @media only screen and (min-width: 768px) {
        .section_form .choose_item li:before {
          width: 40px;
          height: 40px; } }
    .section_form .choose_item li.active {
      color: #000; }
      .section_form .choose_item li.active:before {
        background-color: #f74667; }

.section_form h3 {
  font-size: 1.8rem;
  font-weight: 700;
  color: #000;
  text-transform: uppercase;
  padding-top: 40px;
  padding-bottom: 20px; }
  @media only screen and (min-width: 768px) {
    .section_form h3 {
      padding-top: 75px;
      padding-bottom: 30px;
      font-size: 2rem; } }
  @media only screen and (min-width: 992px) {
    .section_form h3 {
      padding-top: 100px;
      padding-bottom: 40px;
      font-size: 2.2rem; } }

.section_form form label.col, .section_form .form label.col {
  padding: 15px;
  display: block;
  width: 100%;
  min-height: 1px; }
  @media only screen and (min-width: 768px) {
    .section_form form label.col, .section_form .form label.col {
      float: left;
      width: 50%; } }
  @media only screen and (min-width: 992px) {
    .section_form form label.col, .section_form .form label.col {
      width: 33.333%; } }
  .section_form form label.col input, .section_form .form label.col input {
    width: 100%;
    max-width: 100%;
    display: block;
    background-color: #f0f0f1;
    border: 0;
    color: #747474;
    padding: 16px 15px; }
    @media only screen and (min-width: 768px) {
      .section_form form label.col input, .section_form .form label.col input {
        padding: 18px 15px; } }
    @media only screen and (min-width: 992px) {
      .section_form form label.col input, .section_form .form label.col input {
        padding: 20px; } }
    @media only screen and (min-width: 1400px) {
      .section_form form label.col input, .section_form .form label.col input {
        padding: 22px; } }
    .section_form form label.col input:focus, .section_form .form label.col input:focus {
      outline: none; }
    .section_form form label.col input::-webkit-input-placeholder, .section_form .form label.col input::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: #747474;
      font-weight: 600;
      font-size: 1.4rem; }
    .section_form form label.col input::-moz-placeholder, .section_form .form label.col input::-moz-placeholder {
      /* Firefox 19+ */
      color: #747474;
      font-weight: 600;
      font-size: 1.4rem; }
    .section_form form label.col input:-ms-input-placeholder, .section_form .form label.col input:-ms-input-placeholder {
      /* IE 10+ */
      color: #747474;
      font-weight: 600;
      font-size: 1.4rem; }
    .section_form form label.col input:-moz-placeholder, .section_form .form label.col input:-moz-placeholder {
      /* Firefox 18- */
      color: #747474;
      font-weight: 600;
      font-size: 1.4rem; }
  .section_form form label.col:last-of-type, .section_form .form label.col:last-of-type {
    width: 100%;
    /*@include respond-to(ipad){
            width: 50%;
          }
          @include respond-to(desktop){
            width: 66.6667%;
          }*/ }
  .section_form form label.col.col_textarea, .section_form .form label.col.col_textarea {
    width: 100%; }

.section_form form label .select2, .section_form .form label .select2 {
  display: block;
  width: 100% !important;
  max-width: 100%;
  background-color: #f0f0f1;
  border: 0;
  padding: 10px 15px; }
  @media only screen and (min-width: 768px) {
    .section_form form label .select2, .section_form .form label .select2 {
      padding: 12px 15px; } }
  @media only screen and (min-width: 992px) {
    .section_form form label .select2, .section_form .form label .select2 {
      padding: 14px 20px; } }
  @media only screen and (min-width: 1400px) {
    .section_form form label .select2, .section_form .form label .select2 {
      padding: 16px 20px; } }
  .section_form form label .select2 .selection, .section_form .form label .select2 .selection {
    display: block; }
    .section_form form label .select2 .selection .select2-selection, .section_form .form label .select2 .selection .select2-selection {
      position: relative;
      border: 0;
      background: none;
      text-align: left; }
      .section_form form label .select2 .selection .select2-selection .select2-selection__rendered, .section_form .form label .select2 .selection .select2-selection .select2-selection__rendered {
        padding: 0;
        color: #747474;
        font-weight: 600;
        font-size: 1.4rem; }

.section_form form label textarea, .section_form .form label textarea {
  width: 100%;
  max-width: 100%;
  display: block;
  background-color: #f0f0f1;
  border: 0;
  color: #747474;
  padding: 16px 15px;
  min-height: 80px;
  resize: none; }
  @media only screen and (min-width: 768px) {
    .section_form form label textarea, .section_form .form label textarea {
      min-height: 100px;
      padding: 18px 15px; } }
  @media only screen and (min-width: 992px) {
    .section_form form label textarea, .section_form .form label textarea {
      min-height: 120px;
      padding: 20px; } }
  @media only screen and (min-width: 1400px) {
    .section_form form label textarea, .section_form .form label textarea {
      min-height: 140px;
      padding: 22px; } }
  .section_form form label textarea:focus, .section_form .form label textarea:focus {
    outline: none; }
  .section_form form label textarea::-webkit-input-placeholder, .section_form .form label textarea::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #747474;
    font-weight: 600;
    font-size: 1.4rem; }
  .section_form form label textarea::-moz-placeholder, .section_form .form label textarea::-moz-placeholder {
    /* Firefox 19+ */
    color: #747474;
    font-weight: 600;
    font-size: 1.4rem; }
  .section_form form label textarea:-ms-input-placeholder, .section_form .form label textarea:-ms-input-placeholder {
    /* IE 10+ */
    color: #747474;
    font-weight: 600;
    font-size: 1.4rem; }
  .section_form form label textarea:-moz-placeholder, .section_form .form label textarea:-moz-placeholder {
    /* Firefox 18- */
    color: #747474;
    font-weight: 600;
    font-size: 1.4rem; }

.section_form form .conf, .section_form .form .conf {
  padding: 10px 15px; }
  .section_form form .conf label, .section_form .form .conf label {
    cursor: pointer;
    text-align: left;
    display: block;
    color: #a2a2a2;
    font-size: 1.4rem;
    font-weight: 400; }
    .section_form form .conf label input[type=checkbox], .section_form .form .conf label input[type=checkbox] {
      margin: 0 10px 0 0;
      display: inline-block;
      width: 20px;
      height: 20px;
      border: 1px solid #a2a2a2;
      border-radius: 0;
      background: #fff;
      vertical-align: middle; }

.section_form form .submit_wrap, .section_form .form .submit_wrap {
  text-align: center;
  padding: 20px 0; }
  .section_form form .submit_wrap input[type=submit], .section_form .form .submit_wrap input[type=submit] {
    background-color: #f74667;
    color: #fff;
    font-size: 1.8rem;
    padding: 10px 20px;
    border: 2px solid transparent;
    -webkit-transition: all 200ms ease-in-out;
    -moz-transition: all 200ms ease-in-out;
    -ms-transition: all 200ms ease-in-out;
    -o-transition: all 200ms ease-in-out;
    transition: all 200ms ease-in-out; }
    @media only screen and (min-width: 768px) {
      .section_form form .submit_wrap input[type=submit], .section_form .form .submit_wrap input[type=submit] {
        padding: 12px 25px;
        border-width: 1px; } }
    @media only screen and (min-width: 992px) {
      .section_form form .submit_wrap input[type=submit], .section_form .form .submit_wrap input[type=submit] {
        padding: 15px 30px; } }
    @media only screen and (min-width: 1400px) {
      .section_form form .submit_wrap input[type=submit], .section_form .form .submit_wrap input[type=submit] {
        padding: 20px 40px; } }
    .section_form form .submit_wrap input[type=submit]:hover, .section_form .form .submit_wrap input[type=submit]:hover {
      border-color: #f74667;
      background-color: transparent;
      color: #f74667; }

.section_condition {
  background-color: #0247f8;
  text-align: center; }
  @media only screen and (min-width: 768px) {
    .section_condition {
      text-align: left; } }
  .section_condition .container {
    font-size: 0; }
  .section_condition .col_helper {
    min-height: 1px;
    width: 100%; }
    @media only screen and (min-width: 768px) {
      .section_condition .col_helper {
        width: 25%;
        float: left;
        padding: 0 15px; } }
    @media only screen and (min-width: 1400px) {
      .section_condition .col_helper {
        width: 25%; } }
  .section_condition .wrap {
    position: relative;
    background-color: #0247f8;
    padding: 30px 0;
    min-height: 210px; }
    @media only screen and (min-width: 768px) {
      .section_condition .wrap {
        min-height: 160px;
        padding: 40px 0; } }
    @media only screen and (min-width: 992px) {
      .section_condition .wrap {
        padding: 50px 0; } }
    @media only screen and (min-width: 1400px) {
      .section_condition .wrap {
        min-height: 210px;
        padding: 60px 0; } }
    .section_condition .wrap .left {
      padding: 0 0 15px 0; }
      @media only screen and (min-width: 768px) {
        .section_condition .wrap .left {
          position: absolute;
          left: 0;
          bottom: 0;
          padding: 0; } }
      .section_condition .wrap .left img {
        max-width: 120px;
        margin: 0 auto;
        display: block; }
        @media only screen and (min-width: 768px) {
          .section_condition .wrap .left img {
            max-width: 180px;
            padding-left: 15px; } }
        @media only screen and (min-width: 992px) {
          .section_condition .wrap .left img {
            max-width: 200px; } }
        @media only screen and (min-width: 1400px) {
          .section_condition .wrap .left img {
            max-width: 100%; } }
    .section_condition .wrap .right {
      color: #fff;
      min-height: 1px;
      width: 100%; }
      @media only screen and (min-width: 768px) {
        .section_condition .wrap .right {
          width: 75%;
          float: left;
          padding: 0 15px; } }
      @media only screen and (min-width: 1400px) {
        .section_condition .wrap .right {
          width: 75%; } }
      .section_condition .wrap .right h4 {
        font-size: 2rem;
        color: #fff;
        font-weight: 700; }
        @media only screen and (min-width: 768px) {
          .section_condition .wrap .right h4 {
            font-size: 2.4rem; } }
      .section_condition .wrap .right p {
        font-size: 1.6rem;
        color: #fff;
        font-weight: 400; }

.section_advantages_d .col {
  min-height: 1px;
  padding: 15px; }
  @media only screen and (min-width: 768px) {
    .section_advantages_d .col {
      float: left;
      width: 50%; } }
  @media only screen and (min-width: 992px) {
    .section_advantages_d .col {
      padding: 0 15px;
      width: 33.333%; } }
  .section_advantages_d .col .in .img {
    width: 150px;
    height: 125px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-shadow: 0 0 50px 10px #d3d1d1;
    -moz-box-shadow: 0 0 50px 10px #d3d1d1;
    box-shadow: 0 0 50px 10px #d3d1d1;
    margin: 0 auto 30px; }
    .section_advantages_d .col .in .img img {
      max-width: 100%; }
  .section_advantages_d .col .in h5 {
    font-size: 1.6rem;
    font-weight: 600;
    margin: 0 0 20px; }

.our_team h3 {
  margin: 0 0 30px;
  font-size: 1.6rem;
  font-weight: 700; }
  @media only screen and (min-width: 768px) {
    .our_team h3 {
      margin: 0 0 40px; } }
  @media only screen and (min-width: 992px) {
    .our_team h3 {
      margin: 0 0 60px; } }
  @media only screen and (min-width: 1400px) {
    .our_team h3 {
      margin: 0 0 70px; } }

.our_team .team_list .item {
  padding: 0 15px; }
  @media only screen and (min-width: 768px) {
    .our_team .team_list .item {
      padding: 0; } }
  .our_team .team_list .item .b_s {
    margin: 0 0 20px; }
  .our_team .team_list .item .img img {
    max-width: 100%; }
  .our_team .team_list .item .name {
    display: block;
    text-align: center;
    font-weight: 700;
    text-transform: uppercase;
    padding: 15px;
    margin: -30px 0 0 0;
    background: #fff;
    position: relative; }
  .our_team .team_list .item .social_links {
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: distribute;
    justify-content: space-around; }
    .our_team .team_list .item .social_links li {
      display: inline-block; }
      .our_team .team_list .item .social_links li a {
        color: #000;
        font-weight: 700;
        text-transform: uppercase;
        font-size: 1.2rem; }
        .our_team .team_list .item .social_links li a:hover {
          color: #0247f8; }

.our_team .team_list.owl-carousel .owl-nav {
  padding: 40px 0; }
  .our_team .team_list.owl-carousel .owl-nav button.owl-next, .our_team .team_list.owl-carousel .owl-nav button.owl-prev {
    padding: 0 15px !important; }
    .our_team .team_list.owl-carousel .owl-nav button.owl-next .arrow_l, .our_team .team_list.owl-carousel .owl-nav button.owl-next .arrow_r, .our_team .team_list.owl-carousel .owl-nav button.owl-prev .arrow_l, .our_team .team_list.owl-carousel .owl-nav button.owl-prev .arrow_r {
      width: 40px;
      height: 40px;
      border: 1px solid #000;
      border-radius: 50%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center; }
      .our_team .team_list.owl-carousel .owl-nav button.owl-next .arrow_l i, .our_team .team_list.owl-carousel .owl-nav button.owl-next .arrow_r i, .our_team .team_list.owl-carousel .owl-nav button.owl-prev .arrow_l i, .our_team .team_list.owl-carousel .owl-nav button.owl-prev .arrow_r i {
        font-size: 2rem; }
        @media only screen and (min-width: 768px) {
          .our_team .team_list.owl-carousel .owl-nav button.owl-next .arrow_l i, .our_team .team_list.owl-carousel .owl-nav button.owl-next .arrow_r i, .our_team .team_list.owl-carousel .owl-nav button.owl-prev .arrow_l i, .our_team .team_list.owl-carousel .owl-nav button.owl-prev .arrow_r i {
            font-size: 2.4rem; } }
  .our_team .team_list.owl-carousel .owl-nav button:hover span {
    border: 1px solid #f6637f !important; }
    .our_team .team_list.owl-carousel .owl-nav button:hover span i {
      color: #f6637f; }

.section_service {
  background-color: #fafafa; }

.section_we_work {
  background-position: 50% 50%;
  background-repeat: no-repeat !important;
  background-size: cover; }
