.section_advantages_d{
  .col{
    min-height: 1px;
    padding: 15px;
    @include respond-to(ipad){
      float: left;
      width: 50%;
    }
    @include respond-to(desktop){
      padding: 0 15px;
      width: 33.333%;
    }
    .in{
      @include respond-to(ipad){}
      @include respond-to(desktop){}
      @include respond-to(large_desktop){}
      .img{
        width: 150px;
        height: 125px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-shadow: 0 0 50px 10px rgba(211,209,209,1);
        -moz-box-shadow: 0 0 50px 10px rgba(211,209,209,1);
        box-shadow: 0 0 50px 10px rgba(211,209,209,1);
        margin: 0 auto 30px;
        img{
          max-width: 100%;
        }
      }
      h5{
        font-size: 1.6rem;
        font-weight: 600;
        margin: 0 0 20px;
      }
    }
  }
}