.section_condition{
  background-color: #0247f8;
  text-align: center;
  @include respond-to(ipad){
    text-align: left;
  }
  .container{
    font-size: 0;
  }
  .col_helper{
    min-height: 1px;
    width: 100%;
    @include respond-to(ipad){
      width: 25%;
      float: left;
      padding: 0 15px;
    }
    @include respond-to(large_desktop){
      width: 25%;
    }
  }
  .wrap{
    position: relative;
    background-color: #0247f8;
    padding: 30px 0;
    min-height: 210px;
    @include respond-to(ipad){
      min-height: 160px;
      padding: 40px 0;
    }
    @include respond-to(desktop){
      padding: 50px 0;
    }
    @include respond-to(large_desktop){
      min-height: 210px;
      padding: 60px 0;
    }
    .left{
      padding: 0 0 15px 0;
      @include respond-to(ipad){
        position: absolute;
        left: 0;
        bottom: 0;
        padding: 0;
      }
      img{
        max-width: 120px;
        margin: 0 auto;
        display: block;
        @include respond-to(ipad){
          max-width: 180px;
          padding-left: 15px;
        }
        @include respond-to(desktop){
          max-width: 200px;
        }
        @include respond-to(large_desktop){
          max-width: 100%;
        }
      }
    }
    .right{
      color: #fff;
      min-height: 1px;
      width: 100%;
      @include respond-to(ipad){
        width: 75%;
        float: left;
        padding: 0 15px;
      }
      @include respond-to(large_desktop){
        width: 75%;
      }
      h4{
        font-size: 2rem;
        color: #fff;
        font-weight: 700;
        @include respond-to(ipad){
          font-size: 2.4rem;
        }
      }
      p{
        font-size: 1.6rem;
        color: #fff;
        font-weight: 400;
      }
      @include respond-to(ipad){}
      @include respond-to(desktop){}
      @include respond-to(large_desktop){}
    }
  }
}