// var colors
$color_white: #fff;
$color_black: #000;

// var media
$ipad: 768px;
$desktop: 992px;
$large_desktop: 1400px;


// mixins
@mixin respond-to($media) {
  @if $media == ipad {
    @media only screen and (min-width: $ipad) { @content; }
  }
  @elseif $media == desktop {
    @media only screen and (min-width: $desktop) { @content; }
  }
  @elseif $media == large_desktop {
    @media only screen and (min-width: $large_desktop) { @content; }
  }
}

@mixin border-radius($radius) {
  border-radius: $radius;
}

@mixin input-placeholder {
  &.placeholder { @content; }
  &:-moz-placeholder { @content; }
  &::-moz-placeholder { @content; }
  &:-ms-input-placeholder { @content; }
  &::-webkit-input-placeholder { @content; }
}

/*
*/

@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @-ms-keyframes #{$animation-name} {
    @content;
  }
  @-o-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}
@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}

@mixin image-2x($image, $width, $height) {
  @media (min--moz-device-pixel-ratio: 1.3),
  (-o-min-device-pixel-ratio: 2.6/2),
  (-webkit-min-device-pixel-ratio: 1.3),
  (min-device-pixel-ratio: 1.3),
  (min-resolution: 1.3dppx) {
    background-image: url($image);
    background-size: $width $height;
  }
}
//Usage
/*div.logo {
  background: url("logo.png") no-repeat;
  @include image-2x("logo2x.png", 100px, 25px);
}*/

@mixin background-gradient($start-color, $end-color, $orientation) {
  background: $start-color;

  @if $orientation == 'vertical' {
    background: -webkit-linear-gradient(top, $start-color, $end-color);
    background: linear-gradient(to bottom, $start-color, $end-color);
  } @else if $orientation == 'horizontal' {
    background: -webkit-linear-gradient(left, $start-color, $end-color);
    background: linear-gradient(to right, $start-color, $end-color);
  } @else {
    background: -webkit-radial-gradient(center, ellipse cover, $start-color, $end-color);
    background: radial-gradient(ellipse at center, $start-color, $end-color);
  }
}
//Usage:
//$start-color, $end-color, $orientation - vertical/horizontal/radial
/*.foo {
  @include background-gradient(red, black, 'vertical');
}*/
//Output
/*.foo {
  background: -webkit-linear-gradient(top, red, black);
  background: linear-gradient(to bottom, red, black);
}*/




@mixin font-face($font-name, $file-name, $weight: normal, $style: normal) {
  @font-face {
    font-family: quote($font-name);
    src: url($file-name + '.eot');
    src: url($file-name + '.eot?#iefix')  format('embedded-opentype'),
    url($file-name + '.woff') format('woff'),
    url($file-name + '.ttf')  format('truetype'),
    url($file-name + '.svg##{$font-name}')  format('svg');
    font-weight: $weight;
    font-style: $style;
  }
}
//Usage
//@include font-face('gotham', '/fonts/gotham');