.section_form{
  h2{}
  .sub_title{}
  .choose_item{
    text-align: left;
    counter-reset: item;
    margin: 0 0 30px;
    @extend .row;
    padding-top: 30px;
    li{
      counter-increment: item;
      cursor: pointer;
      position: relative;
      color: #dadada;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      float: left;
      padding-left: 40px;
      font-size: 1.6rem;
      font-weight: 600;
      margin-right: 15px;
      @include respond-to(ipad){
        padding-left: 50px;
        min-height: 40px;
        margin-right: 20px;
      }
      @include respond-to(desktop){
        margin-right: 40px;
      }
      &:before{
        content: counter(item, decimal-leading-zero);
        counter-increment: li;
        position: absolute;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        color: #fff;
        font-size: 1.4rem;
        font-weight: 400;
        background-color: #dadada;
        border-radius: 50%;
        left: 0;
        width: 35px;
        height: 35px;
        @include respond-to(ipad){
          width: 40px;
          height: 40px;
        }
      }
      &.active{
        color: #000;
        &:before{
          background-color: #f74667;
        }
      }
    }
  }
  h3{
    font-size: 1.8rem;
    font-weight: 700;
    color: #000;
    text-transform: uppercase;
    padding-top: 40px;
    padding-bottom: 20px;
    @include respond-to(ipad){
      padding-top: 75px;
      padding-bottom: 30px;
      font-size: 2rem;
    }
    @include respond-to(desktop){
      padding-top: 100px;
      padding-bottom: 40px;
      font-size: 2.2rem;
    }
  }
  form, .form{
    @extend .row;
    label{
      &.col{
        padding: 15px;
        display: block;
        width: 100%;
        min-height: 1px;
        @include respond-to(ipad){
          float: left;
          width: 50%;
        }
        @include respond-to(desktop){
          width: 33.333%;
        }
        input{
          width: 100%;
          max-width: 100%;
          display: block;
          background-color: #f0f0f1;
          border: 0;
          color: #747474;
          padding: 16px 15px;
          @include respond-to(ipad){
            padding: 18px 15px;
          }
          @include respond-to(desktop){
            padding: 20px;
          }
          @include respond-to(large_desktop){
            padding: 22px;
          }
          &:focus{
            outline: none;
          }
          &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            color: #747474;
            font-weight: 600;
            font-size: 1.4rem;
          }
          &::-moz-placeholder { /* Firefox 19+ */
            color: #747474;
            font-weight: 600;
            font-size: 1.4rem;
          }
          &:-ms-input-placeholder { /* IE 10+ */
            color: #747474;
            font-weight: 600;
            font-size: 1.4rem;
          }
          &:-moz-placeholder { /* Firefox 18- */
            color: #747474;
            font-weight: 600;
            font-size: 1.4rem;
          }
        }
        &:last-of-type{
          width: 100%;
          /*@include respond-to(ipad){
            width: 50%;
          }
          @include respond-to(desktop){
            width: 66.6667%;
          }*/
        }
        &.col_textarea{
          width: 100%;
        }
      }
      .select2{
        display: block;
        width: 100%!important;
        max-width: 100%;
        background-color: #f0f0f1;
        border: 0;
        padding: 10px 15px;
        @include respond-to(ipad){
          padding: 12px 15px;
        }
        @include respond-to(desktop){
          padding: 14px 20px;
        }
        @include respond-to(large_desktop){
          padding: 16px 20px;
        }
        .selection{
          display: block;
          .select2-selection{
            position: relative;
            border: 0;
            background: none;
            text-align: left;
            .select2-selection__rendered{
              padding: 0;
              color: #747474;
              font-weight: 600;
              font-size: 1.4rem;
            }
          }
        }
      }
      textarea{
        width: 100%;
        max-width: 100%;
        display: block;
        background-color: #f0f0f1;
        border: 0;
        color: #747474;
        padding: 16px 15px;
        min-height: 80px;
        resize: none;
        @include respond-to(ipad){
          min-height: 100px;
          padding: 18px 15px;
        }
        @include respond-to(desktop){
          min-height: 120px;
          padding: 20px;
        }
        @include respond-to(large_desktop){
          min-height: 140px;
          padding: 22px;
        }
        &:focus{
          outline: none;
        }
        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
          color: #747474;
          font-weight: 600;
          font-size: 1.4rem;
        }
        &::-moz-placeholder { /* Firefox 19+ */
          color: #747474;
          font-weight: 600;
          font-size: 1.4rem;
        }
        &:-ms-input-placeholder { /* IE 10+ */
          color: #747474;
          font-weight: 600;
          font-size: 1.4rem;
        }
        &:-moz-placeholder { /* Firefox 18- */
          color: #747474;
          font-weight: 600;
          font-size: 1.4rem;
        }

      }
    }
    .conf{
      padding: 10px 15px;
      label{
        cursor: pointer;
        text-align: left;
        display: block;
        color: #a2a2a2;
        font-size: 1.4rem;
        font-weight: 400;
        input[type=checkbox]{
          margin: 0 10px 0 0;
          display: inline-block;
          width: 20px;
          height: 20px;
          border: 1px solid #a2a2a2;
          border-radius: 0;
          background: #fff;
          vertical-align: middle;
        }
      }
    }
    .submit_wrap{
      text-align: center;
      padding: 20px 0;
      input[type=submit]{
        background-color: #f74667;
        color: #fff;
        font-size: 1.8rem;
        padding: 10px 20px;
        border: 2px solid transparent;
        -webkit-transition: all 200ms ease-in-out;
        -moz-transition: all 200ms ease-in-out;
        -ms-transition: all 200ms ease-in-out;
        -o-transition: all 200ms ease-in-out;
        transition: all 200ms ease-in-out;
        @include respond-to(ipad){
          padding: 12px 25px;
          border-width: 1px;
        }
        @include respond-to(desktop){
          padding: 15px 30px;
        }
        @include respond-to(large_desktop){
          padding: 20px 40px;
        }
        &:hover{
          border-color: #f74667;
          background-color: transparent;
          color: #f74667;
        }
      }
    }
  }
}