.our_team{
  h3{
    margin: 0 0 30px;
    font-size: 1.6rem;
    font-weight: 700;
    @include respond-to(ipad){
      margin: 0 0 40px;
    }
    @include respond-to(desktop){
      margin: 0 0 60px;
    }
    @include respond-to(large_desktop){
      margin: 0 0 70px;
    }
  }
  .team_list{
    .item{
      padding: 0 15px;
      @include respond-to(ipad){
        padding: 0;
      }
      .b_s{
        margin: 0 0 20px;
      }
      .img{
        img{
          max-width: 100%;
        }
      }
      .name{
        display: block;
        text-align: center;
        font-weight: 700;
        text-transform: uppercase;
        padding: 15px;
        margin: -30px 0 0 0;
        background: #fff;
        position: relative;
      }
      .social_links{
        text-align: center;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: distribute;
        justify-content: space-around;
        li{
          display: inline-block;
          a{
            color: #000;
            font-weight: 700;
            text-transform: uppercase;
            font-size: 1.2rem;
            &:hover{
              color: #0247f8;
            }
          }
        }
      }
    }
    &.owl-carousel{
      .owl-nav{
        padding: 40px 0;
        button{
          &.owl-next, &.owl-prev{
            padding: 0 15px!important;
            .arrow_l, .arrow_r{
              width: 40px;
              height: 40px;
              border: 1px solid #000;
              border-radius: 50%;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              -webkit-box-pack: center;
              -ms-flex-pack: center;
              justify-content: center;
              i{
                font-size: 2rem;
                @include respond-to(ipad){
                  font-size: 2.4rem;
                }
              }
            }
          }
          &:hover{
            span{
              border: 1px solid #f6637f!important;
              i{
                color: #f6637f;
              }
            }
          }
        }
      }
    }
  }
}