.course_list_wrap{
  text-align: center;
  padding: 30px 0;
  @include respond-to(ipad){
    padding: 60px 0;
  }
  @include respond-to(desktop){
    padding: 70px 0;
  }
  @include respond-to(large_desktop){
    padding: 80px 0;
  }
  .container{
    padding: 0 30px;
    @include respond-to(ipad){
      padding: 0 50px;
    }
    .course_list{
      margin: 0 -30px;
      @include respond-to(ipad){
        margin: 0 -50px;
      }
      &_item{
        width: 100%;
        padding: 30px;
        @include respond-to(ipad){
          width: 50%;
        }
        @include respond-to(desktop){
          width: 33.333%;
        }
        @include respond-to(large_desktop){
          width: 33.333%;
        }
        .btn{
          padding: 6px 20px;
          @include respond-to(ipad){
            padding: 8px 28px;
          }
          @include respond-to(desktop){
            border: 2px solid transparent;
            padding: 10px 32px;
          }
          @include respond-to(large_desktop){
            padding: 12px 36px;
          }
        }
      }
    }
  }
  p{
    text-align: center;
  }
  .course_list{
    @extend .row;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    @include respond-to(large_desktop){
      padding: 40px 0 0 0;
    }
    &_item{
      padding: 15px;
      @include respond-to(ipad){
        width: 50%;
        float: left;
      }
      @include respond-to(desktop){
        width: 33.333%;
      }
      &.start{
        .start_date{}
      }
      &.current{
        .start_date{
          color: #f74667;
          &:before{
            background-color: #f74667;
          }
        }
      }
      &_inner{
        position: relative;
        background-color: #f5f5f5;
        padding: 40px 30px;
        height: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: baseline;
        -ms-flex-align: baseline;
        align-items: baseline;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        &:before, &:after{
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 3px;
          height: 10%;
          background-color: #0247f8;
        }
        &:after{
          height: 33.333%;
          top: auto;
          left: auto;
          right: 0;
          bottom: 0;
        }
        .list_time{
          position: absolute;
          top: -25px;
          right: -28px;
          @extend .clearfix;
          li{
            background-color: #f35472;
            width: 50px;
            height: 50px;
            border-radius: 4px;
            color: #fff;
            float: left;
            margin: 0 5px;
            font-size: 1.1rem;
            span{
              font-weight: 600;
              display: block;
              font-size: 1.6rem;
              @include respond-to(ipad){
                font-size: 1.8rem;
              }
              @include respond-to(desktop){
                font-size: 2rem;
              }
            }
          }
        }
        .start_date{
          text-align: left;
          color: #000;
          font-weight: 600;
          font-size: 1.4rem;
          position: relative;
          margin: 0 0 10px;
          &:before{
            content: '';
            width: 20px;
            height: 1px;
            background-color: #000;
            vertical-align: middle;
            display: inline-block;
            margin: 0 15px 2px 0;
          }
        }
        .spec_name{
          text-align: left;
          color: #000;
          font-size: 2rem;
          text-transform: uppercase;
          font-weight: 800;
          @include respond-to(ipad){
            font-size: 2rem;
          }
          @include respond-to(desktop){
            font-size: 2.8rem;
          }
        }
        .price_tag{
          text-align: left;
          color: #000;
          font-weight: 700;
          font-size: 2rem;
          margin: 0 0 10px;
          @include respond-to(ipad){
            font-size: 2.4rem;
            margin: 0 0 20px;
          }
          @include respond-to(desktop){
            font-size: 2.8rem;
            margin: 0 0 30px;
          }
        }
        .schedule_list{
          margin: 0 0 15px;
          text-align: left;
          li{
            color: #000;
            font-size: 1.4rem;
            font-weight: 400;
          }
        }
        .btn{
          display: inline-block;
          color: #fff;
          background-color: #0247f8;
          font-size: 1.4rem;
          @include respond-to(ipad){
            font-size: 1.5rem;
          }
          &:hover{
            color: #0247f8;
            border-color: #0247f8;
            background-color: transparent;
          }
        }
      }
    }
  }
}